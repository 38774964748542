import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SendIcon from '@mui/icons-material/Send';
import { doLogin } from '../store/api';
import { Appcontext, AppDpx } from '../context/AppContext';
import { LOADING_SET, PROFILE_SET } from '../context/Action';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Email, Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useContext(AppDpx);
  const { user } = useContext(Appcontext);
  const { loading } = useContext(Appcontext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [al, showAlert] = useState<{
    type: AlertColor;
    msg: string;
  } | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.id) {
      navigate('/admin');
    }
  }, [user, navigate]);

  const _login = async () => {
    dispatch({ type: LOADING_SET, data: true });
    if (email.length < 1 || password.length < 1) {
      showAlert({ type: 'info', msg: 'forms.login.required' });
      dispatch({ type: LOADING_SET, data: false });
      return;
    }
    const d = await doLogin({
      email: email.trim(),
      password: password.trim(),
    });
    if (d.data && d?.data?.id !== null) {
      const user = { ...d.data, auth: true };
      localStorage.setItem('user', JSON.stringify(user));
      dispatch({ type: PROFILE_SET, data: user });
      showAlert({ type: 'success', msg: 'forms.login.successmsg' });
      navigate('/admin', { state: user });
    } else {
      showAlert({ type: 'error', msg: 'forms.login.errormsg' });
    }
    dispatch({ type: LOADING_SET, data: false });
  };
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(45deg, #f3f4f6 30%, #e5e7eb 90%)',
        padding: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: '400px',
          width: '100%',
          p: 4,
          borderRadius: 2,
          background: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            mb: 4,
          }}
        >
          <Avatar
            sx={{
              width: 56,
              height: 56,
              bgcolor: 'primary.main',
              mb: 1,
            }}
          >
            <LockPersonIcon />
          </Avatar>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: 'primary.main',
            }}
          >
            {t('forms.login.title')}
          </Typography>
        </Box>

        {loading && <LinearProgress sx={{ mb: 2 }} />}

        {al && (
          <Alert
            severity={al?.type}
            onClose={() => showAlert(null)}
            sx={{ mb: 2 }}
          >
            {t(al?.msg)}
          </Alert>
        )}

        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2.5,
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            id="email"
            label={t('forms.common.email')}
            variant="outlined"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="action" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />

          <TextField
            fullWidth
            id="password"
            label={t('forms.common.password')}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockPersonIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />

          <Button
            fullWidth
            variant="contained"
            onClick={_login}
            endIcon={<SendIcon />}
            sx={{
              mt: 2,
              py: 1.5,
              textTransform: 'none',
              fontSize: '1.1rem',
              fontWeight: 500,
              borderRadius: 1.5,
            }}
          >
            {t('forms.common.login')}
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => navigate('/forgot')}
              sx={{
                textTransform: 'none',
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {t('forms.common.forgot')}
            </Button>
            <Button
              onClick={() => navigate('/newuser')}
              sx={{
                textTransform: 'none',
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {t('forms.login.access')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
