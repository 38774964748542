import {
  Box,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SearchIcon from '@mui/icons-material/Search';
import Portal from './Portal';
import { Link } from 'react-router-dom';
import { deleteDoc, deleteS3AndDb, myUploadedDocs } from '../../store/api';
import { useContext, useState } from 'react';
import { Appcontext } from '../../context/AppContext';
import Datepicker from '../../components/portal/Datepicker';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@mui/icons-material';
import DocumentsGrid from './DocumentsGrid';
import useAlert from '../../hooks/useAlert';

// interface SearchFilters {
//   isSearch: boolean;
//   [key: string]: any;
//   from?: Dayjs | null;
//   to?: Dayjs | null;
// }

const Docs: React.FC = () => {
  const { user } = useContext(Appcontext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { alertDisplay, setAlert } = useAlert();
  const [searchFilters, setSearchFilters] = useState({
    text: '',
    filterKey: 'Find by:',
    dateRange: {
      from: null as Dayjs | null,
      to: null as Dayjs | null,
    },
  });
  const { data: documents = [], isLoading } = useQuery({
    queryKey: ['my-docs', user?.id],
    queryFn: () => myUploadedDocs(user?.id as string),
    enabled: !!user?.id,
  });

  const deleteMutation = useMutation(
    ({ key, id }: { key: string; id: string }) => deleteS3AndDb(key, id),
    {
      onSuccess: () => {
        // Refetch the documents list
        queryClient.invalidateQueries(['my-docs', user?.id]);

        setAlert({
          open: true,
          type: 'success',
          message: 'File object deleted successfully!',
        });
      },
      onError: () => {
        setAlert({
          open: true,
          type: 'error',
          message:
            t('portal.docs.errormsg') ||
            'Failed to delete file object, please retry!',
        });
      },
    }
  );

  const deleteDocumentMutation = useMutation(
    (docId: string) => deleteDoc(docId),
    {
      onSuccess: () => {
        // Refetch documents after successful deletion
        queryClient.invalidateQueries(['my-docs', user?.id]);

        setAlert({
          message: 'Document deleted successfully',
          type: 'success',
          open: true,
        });
      },
      onError: () => {
        setAlert({
          message: 'An error occurred while deleting the document',
          type: 'error',
          open: true,
        });
      },
    }
  );

  const handleSearch = () => {
    // const filters: SearchFilters = {
    //   isSearch: true,
    //   [searchFilters.filterKey]: searchFilters.text,
    //   from: searchFilters.dateRange.from,
    //   to: searchFilters.dateRange.to,
    // };
    // fetchDocuments(filters);
  };
  const handleDeleteObject = async (key: string, id: string) => {
    deleteMutation.mutate({ key, id });
    // try {
    //   await deleteS3AndDb(key, id);
    //   setAlert({
    //     open: true,
    //     type: "success",
    //     message: "File object deleted successfully!",
    //   });
    // } catch (error) {
    //   setAlert({
    //     open: true,
    //     type: "error",
    //     message:
    //       t("portal.docs.errormsg") ||
    //       "Failed to delete file object, please retry!",
    //   });
    // }
  };

  return (
    <Portal>
      <Container>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h5">
            {t('portal.docs.mydocs')} ({documents?.length || 0})
          </Typography>
          <IconButton
            component={Link}
            to="/admin/docadd"
            color="primary"
            sx={{ '&:hover': { transform: 'scale(1.1)' } }}
          >
            <AddCircle />
          </IconButton>
        </Box>

        {/* Alerts and Progress */}
        {alertDisplay()}
        {isLoading && <LinearProgress sx={{ mb: 2 }} />}

        {/* Search Section */}
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="search"
                variant="outlined"
                placeholder="Search..."
                value={searchFilters.text}
                onChange={(e) =>
                  setSearchFilters((prev) => ({
                    ...prev,
                    text: e.target.value,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <TextField
                      select
                      label="Find by:"
                      value={searchFilters.filterKey}
                      onChange={(e) =>
                        setSearchFilters((prev) => ({
                          ...prev,
                          filterKey: e.target.value,
                        }))
                      }
                      sx={{ minWidth: 120, mr: 1 }}
                    >
                      <MenuItem value="email">
                        {t('forms.common.email')}
                      </MenuItem>
                      <MenuItem value="firstname">
                        {t('forms.common.firstname')}
                      </MenuItem>
                      <MenuItem value="lastname">
                        {t('forms.common.lastname')}
                      </MenuItem>
                      <MenuItem value="id">
                        {t('portal.docs.reference')}
                      </MenuItem>
                    </TextField>
                  ),
                  endAdornment: (
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Datepicker
                  label="From"
                  value={searchFilters.dateRange.from}
                  _change={(date) =>
                    setSearchFilters((prev) => ({
                      ...prev,
                      dateRange: { ...prev.dateRange, from: date },
                    }))
                  }
                />
                <Datepicker
                  label="To"
                  value={searchFilters.dateRange.to}
                  _change={(date) =>
                    setSearchFilters((prev) => ({
                      ...prev,
                      dateRange: { ...prev.dateRange, to: date },
                    }))
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <DocumentsGrid
          documents={documents}
          _delete={handleDeleteObject}
          delDocument={deleteDocumentMutation.mutateAsync}
        />
      </Container>
    </Portal>
  );
};

export default Docs;
