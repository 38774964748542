export const aefinance = {
  contact: {
    name: 'A&E Financial',
    phone: '832-831-3907',
    email: 'contact@aefinancialservicesllc.com',
    emailText: 'Mail Us',
    address: '7324 Southwest Fwy 2 201 Arena Tower Two,Houston',
    address2: 'Texas',
    zip: '77074',
    zip2: '77036',
    website: 'https://www.aefinancialservicesllc.com/',
  },
};
export const resources = [
  {
    title: 'Financial Calculators',
    estitle: 'Calculadoras financieras',
    link: '/',
    icon: 'accounting.webp',
    brief:
      'A tool that allows users to perform various financial calculations, such as calculating loan payments.',
    esbrief:
      'Una herramienta que permite a los usuarios realizar varios cálculos financieros, como el cálculo de pagos de préstamos.',
    calculators: [
      'Auto',
      'Business',
      'Cash Flow',
      'College',
      'Credit and Debit',
      'Home and Morgage',
      'Insurance',
      'Investment',
      'Paycheck and Benefits',
      'Qualified Plans',
      'Retirement',
      'Savings',
      'Taxation',
      'Other',
    ],
  },
  {
    title: 'Record Retention Guide',
    estitle: 'Guía de retención de registros',
    link: 'https://www.irs.gov/businesses/small-businesses-self-employed/how-long-should-i-keep-records#:~:text=Keep%20records%20for%203%20years%20from%20the%20date%20you%20filed,securities%20or%20bad%20debt%20deduction.',
    icon: 'clipboard.webp',
    esbrief:
      'Un recurso que brinda orientación sobre cuánto tiempo mantener varios tipos de registros a efectos fiscales.',
    brief:
      'A resource that provides guidance on how long to keep various types of records for tax purposes.',
    readmore: [
      'Keep records for 3 years if situations (4), (5), and (6) below do not apply to you.',
      'Keep records for 3 years from the date you filed your original return or 2 years from the date you paid the tax, whichever is later, if you file a claim for credit or refund after you file your return.',
      'Keep records for 7 years if you file a claim for a loss from worthless securities or bad debt deduction.',
      'Keep records for 6 years if you do not report income that you should report, and it is more than 25% of the gross income shown on your return.',
      'Keep records indefinitely if you do not file a return.',
      'Keep records indefinitely if you file a fraudulent return.',
      'Keep employment tax records for at least 4 years after the date that the tax becomes due or is paid, whichever is later.',
    ],
  },
  {
    title: 'IRS Publications',
    estitle: 'Publicaciones del IRS',
    link: 'https://www.irs.gov/forms-pubs/more-information',
    icon: 'newspaper.webp',
    download: [
      'Additional information on forms|https://www.irs.gov/forms-pubs/more-information',
      'Accessible tax products|https://www.irs.gov/forms-pubs/accessible-irs-tax-products',
      'Tax eBooks|https://www.irs.gov/forms-pubs/ebook',
      'Tax Instructions|https://www.irs.gov/forms-pubs/changes-to-current-forms-publications',
      'Order IRS forms|https://www.irs.gov/forms-pubs/order-products',
      'IRS Tax Comments|https://www.irs.gov/forms-pubs/comment-on-tax-forms-and-publications',
    ],
    brief:
      'Official documents issued by the Internal Revenue Service that provide guidance on tax-related topics.',
    esbrief:
      'Documentos oficiales emitidos por el Servicio de Impuestos Internos que brindan orientación sobre temas relacionados con los impuestos.',
  },
  {
    title: 'IRS Forms',
    estitle: 'Formularios del IRS',
    link: 'https://www.irs.gov/forms-instructions',
    icon: 'online-registration.webp',
    esbrief:
      'Formularios oficiales que los contribuyentes pueden usar para presentar sus impuestos o solicitar información del IRS.',
    brief:
      'Official forms that taxpayers can use to file their taxes or request information from the IRS.',
    download: [
      'Form 1040|https://www.irs.gov/pub/irs-pdf/f1040.pdf',
      'Form W-4|https://www.irs.gov/pub/irs-pdf/fw4.pdf',
      'Form 1040-ES|https://www.irs.gov/pub/irs-pdf/f1040es.pdf',
      'Form W-9|https://www.irs.gov/pub/irs-pdf/fw9.pdf',
      'Form 4506-T|https://www.irs.gov/pub/irs-pdf/f4506t.pdf',
      'Form 941|https://www.irs.gov/pub/irs-pdf/f941.pdf',
      'Form W-2|https://www.irs.gov/pub/irs-pdf/fw2.pdf',
      'Form 9465|https://www.irs.gov/pub/irs-pdf/f9465.pdf',
      'Form SS-4|https://www.irs.gov/pub/irs-pdf/fss4.pdf',
      'Form W-7|https://www.irs.gov/pub/irs-pdf/fw7.pdf',
    ],
  },
  {
    title: 'Tax Appointment Checklist',
    estitle: 'Lista de verificación de citas de impuestos',
    link: 'https://www.irs.gov/individuals/steps-to-take-now-to-get-a-jump-on-next-years-taxes',
    icon: 'appointment.webp',
    brief:
      'A list of items to bring to a tax appointment, such as documents and information needed to prepare a tax return.',
    esbrief:
      'Una lista de artículos para llevar a una cita de impuestos, como documentos e información necesarios para preparar una declaración de impuestos.',
    download: [
      'Form W2|https://www.irs.gov/forms-pubs/about-form-w-2',
      'Form 1099|https://www.irs.gov/individuals/steps-to-take-now-to-get-a-jump-on-next-years-taxes',
      'Form 1099-K,1099-MISC if gig economy|https://www.irs.gov/individuals/steps-to-take-now-to-get-a-jump-on-next-years-taxes',
      'Form 1099-INT if paid interest|https://www.irs.gov/individuals/steps-to-take-now-to-get-a-jump-on-next-years-taxes',
      'Form 1095-A, Health insurance marketplace statement.|https://www.irs.gov/affordable-care-act/individuals-and-families/premium-tax-credit-claiming-the-credit-and-reconciling-advance-credit-payments',
      'IRS or other agency Letters|https://www.irs.gov/individuals/steps-to-take-now-to-get-a-jump-on-next-years-taxes',
      'CP01A Notice|https://www.irs.gov/identity-theft-fraud-scams/retrieve-your-ip-pin',
      'Unemployment income|https://www.irs.gov/taxtopics/tc418',
      'Interest received|https://www.irs.gov/taxtopics/tc403',
      'Crypto gains and digital assets report|https://www.irs.gov/businesses/small-businesses-self-employed/digital-assets',
    ],
  },
  {
    title: 'Tax Rates',
    estitle: 'Las tasas de impuestos',
    link: '/brackets',
    icon: 'taxation.webp',
    islocal: true,
    esbrief:
      'El porcentaje al que se grava a un contribuyente sobre sus ingresos u otras partidas imponibles. Haga clic en el título de arriba para ver los 7 tramos impositivos del IRS',
    brief:
      'The percentage at which a taxpayer is taxed on their income or other taxable items. Click the title above to see the IRS 7 tax brackets',
  },
  {
    title: 'Tax Due Dates',
    estitle: 'Fechas de vencimiento de impuestos',
    link: 'https://www.irs.gov/pub/irs-pdf/p509.pdf',
    icon: 'due-date.webp',
    brief:
      'The dates by which taxpayers must file their tax returns or pay their taxes.',
    esbrief:
      'Las fechas en que los contribuyentes deben presentar sus declaraciones de impuestos o pagar sus impuestos.',
    download: [
      'IRS Tax Calendar 2023|https://www.irs.gov/pub/irs-pdf/p509.pdf',
      'Tax Calendar|https://www.tax.gov/calendar/',
    ],
  },
  {
    title: 'Taxpayer Rights',
    estitle: 'Derechos de los contribuyentes',
    link: 'https://www.irs.gov/taxpayer-bill-of-rights',
    icon: 'human-rights.webp',
    brief:
      'The rights and protections afforded to taxpayers under the law, including the right to privacy and the right to appeal decisions made by the IRS.',
    esbrief:
      'Los derechos y protecciones que se otorgan a los contribuyentes bajo la ley, incluido el derecho a la privacidad y el derecho de apelación de las decisiones tomadas por el IRS.',
    download: [
      'The Right to Be Informed|https://www.irs.gov/taxpayer-bill-of-rights#informed',
      'The Right to Quality Service|https://www.irs.gov/taxpayer-bill-of-rights#service',
      'The Right to Pay no More than the Correct Amount of Tax|https://www.irs.gov/taxpayer-bill-of-rights#amount',
      "The Right to Challenge the IRS's Position and be Heard|https://www.irs.gov/taxpayer-bill-of-rights#heard",
      'The Right to Appeal an IRS Decision in an Independent Forum|https://www.irs.gov/taxpayer-bill-of-rights#appeal',
      'The Right to Finality|https://www.irs.gov/taxpayer-bill-of-rights#finality',
      'The Right to Privacy|https://www.irs.gov/taxpayer-bill-of-rights#privacy',
      'The Right to Confidentiality|https://www.irs.gov/taxpayer-bill-of-rights#confidentiality',
      'The Right to Retain Representation|https://www.irs.gov/taxpayer-bill-of-rights#representation',
      'The Right to a Fair and Just Tax System|https://www.irs.gov/taxpayer-bill-of-rights#fair',
    ],
  },
  {
    title: 'Tax Glossary',
    estitle: 'Glosario de Impuestos',
    link: 'https://apps.irs.gov/app/understandingTaxes/student/glossary.jsp',
    icon: 'book.webp',
    download: [
      'Understanding taxes|https://apps.irs.gov/app/understandingTaxes/student/glossary.jsp',
    ],
    brief:
      'A list of terms commonly used in the field of taxation, along with their definitions.',
    esbrief:
      'Una lista de términos de uso común en el campo de la fiscalidad, junto con sus definiciones.',
  },
  {
    title: 'Subscribe to Tax Calendar',
    estitle: 'Suscríbete al calendario fiscal',
    link: '/',
    icon: 'notification.webp',
    brief:
      'An option to receive updates or notifications about important tax dates and deadlines.',
    esbrief:
      'Una opción para recibir actualizaciones o notificaciones sobre fechas y plazos de impuestos importantes.',
    download: ['click to subscribe|webcal://www.tax.gov/calendar/calendar.ics'],
  },
  {
    title: 'IRS Withholding Calculator',
    estitle: 'Calculadora de retención del IRS',
    link: 'https://www.irs.gov/individuals/tax-withholding-estimator',
    icon: 'budget.webp',
    brief:
      'A tool that helps taxpayers determine the correct amount of tax to have withheld from their paychecks.',
    esbrief:
      'Una herramienta que ayuda a los contribuyentes a determinar la cantidad correcta de impuestos a retener de sus cheques de pago.',
    download: [
      'Tax Witholding Estimator|https://apps.irs.gov/app/tax-withholding-estimator',
    ],
  },
  {
    title: 'Where is My Refund',
    estitle: '¿Dónde está mi reembolso?',
    link: 'https://www.irs.gov/refunds',
    icon: 'refund.webp',
    brief:
      'A service that allows taxpayers to check the status of their tax refund.',
    esbrief:
      'Un servicio que permite a los contribuyentes verificar el estado de su reembolso de impuestos.',
    download: [
      'Refund Status|https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp',
    ],
  },
];
export const taxRates = [
  {
    id: 1,
    rate: 10,
    single: '$0 to $9,950',
    hoh: '$0 to $14,200',
    marriedjoint: '$0 to $19,900',
    marriedseparate: '$0 to $9,950',
  },
  {
    id: 2,
    rate: 12,
    single: '$9,951 to $40,525',
    hoh: '$14,201 to $54,200',
    marriedjoint: '$19,901 to $81,050',
    marriedseparate: '$9,951 to $40,525',
  },
  {
    id: 3,
    rate: 22,
    single: '$40,526 to $86,375',
    hoh: '$54,201 to $86,350',
    marriedjoint: '$81,051 to $172,750',
    marriedseparate: '$40,526 to $86,375',
  },
  {
    id: 4,
    rate: 24,
    single: '$86,376 to $164,925',
    hoh: '$86,351 to $164,900',
    marriedjoint: '$172,751 to $329,850',
    marriedseparate: '$86,376 to $164,925',
  },
  {
    id: 5,
    rate: 32,
    single: '$164,926 to $209,425',
    hoh: '$164,901 to $209,400',
    marriedjoint: '$329,851 to $418,850',
    marriedseparate: '$164,926 to $209,425',
  },
  {
    id: 6,
    rate: 35,
    single: '$209,426 to $523,600',
    hoh: '$209,401 to $523,600',
    marriedjoint: '$418,851 to $628,300',
    marriedseparate: '$209,426 to $314,450',
  },
  {
    id: 7,
    rate: 37,
    single: '$523,600 and above',
    hoh: '$523,600 and above',
    marriedjoint: '$628,300 and above',
    marriedseparate: '$314,141 and above',
  },
];

export const calcList = [
  {
    type: 'auto',
    details: [
      'aut01: Should I lease or purchase an auto?',
      'aut02: Auto purchase - Loan versus 0% dealer financing?',
      'aut03: What would my auto payments be?',
      'aut04: Should I upgrade to a more fuel efficient vehicle?',
      'aut05: Auto loan - accelerated payoff',
      'aut06: How much vehicle can I afford?',
      'aut07: Should I refinance my auto loan at a lower rate?',
      'aut08: How much can I lower my monthly payment with a new auto loan?',
      'aut09: How much might my vehicle depreciate?',
      'aut10: Calculate my RV loan payments including balloon',
      'aut11: Fuel cost calculator',
      'aut12: Should I buy an electric or gas powered vehicle?',
    ],
  },
  {
    type: 'business',
    details: [
      'bus01: What are the tax savings of a qualified retirement/cafeteria plan?',
      'bus02: What are my new business startup costs?',
      'bus03: Should I pay or charge monthly, quarterly or annually?',
      'bus04: What is the value of my business?',
      'bus05: How many units do I need to sell to breakeven?',
      'bus06: Should I lease or buy equipment?',
      'bus07: What are my business financial ratios?',
      'bus08: What is my employee total compensation package worth?',
      'bus09: What are my tax savings with Section 179 deduction?',
      'bus10: Employer FSA savings calculator',
      'bus11: What should I price my product/service in order to be profitable?',
    ],
  },
  {
    type: 'cashflow',
    details: [
      'bud01: How does inflation impact my standard of living?',
      'bud02: How much am I spending?',
      'bud03: How much do I need for emergencies?',
      'bud04: Should I pay down debt or invest my monthly surplus?',
      'bud05: How long will my money last with systematic withdrawals?',
      'bud06: Should my spouse enter the work force?',
      'bud07: What is my current net worth?',
      'bud08: What is my projected net worth?',
      'bud09: What is my current cash flow?',
      'bud10: What is my projected cash flow?',
      'bud11: What is the value of reducing, postponing or foregoing expenses?',
      'bud12: Historical inflation - Compare purchasing power',
      'bud13: Balance my checkbook',
      'bud14: Cost of living comparison',
    ],
  },
  {
    type: 'college',
    details: [
      'col01: How much should I be saving for college?',
      'col02: Will I be able to pay back my student loans?',
      'col03: What are the advantages of a Coverdell ESA?',
      'col04: What are the advantages of a 529 college savings plan?',
      'col05: What is the value of a college education?',
      'col06: What are the payments on a parental (PLUS) loan?',
      'col07: Should I live at home, on campus, or off campus?',
      "col08: When should I begin saving for my child's college?",
      'col09: What expenses should I expect before college?',
    ],
  },
  {
    type: 'creditanddebit',
    details: [
      'det01: How long will it take to pay off my credit card(s)?',
      'det02: How long until my loan is paid off?',
      'det03: What would my loan payments be?',
      'det04: Do I have too much debt?',
      'det05: What is the balance on my loan?',
      'det06: Should I consolidate my personal debt into a new loan?',
      'det07: Restructuring debts for accelerated payoff',
      'det08: Which is better- Cash up front or payments over time?',
      'det09: What is the impact of making extra payments on my debt?',
      'det10: Should I pay off debt or invest?',
      'det11: Should I transfer my credit card balances?',
      'det12: What is my credit score?',
      'det13: How much can I afford to borrow?',
      'det15: Credit card minimum monthly payment calculator',
      'det16: What is my loan rate?',
      'det17: What monthly payments are needed to pay off my loan sooner?',
    ],
  },
  {
    type: 'homeandmortgage',
    details: [
      'hom01: How much home can I afford?',
      'hom02: Should I refinance my mortgage?',
      'hom03: Mortgage calculator',
      'hom04: Comparing mortgage terms (i.e. 15, 20, 30 year)',
      'hom05: Should I pay discount points for a lower interest rate?',
      'hom06: Should I rent or buy a home?',
      'hom07: Should I convert to a bi-weekly payment schedule?',
      "hom08: Compare a 'no-cost' vs. traditional mortgage",
      'hom09: What are the tax savings generated by my mortgage?',
      'hom10: Which is better, fixed or adjustable-rate mortgage?',
      'hom11: Adjustable rate mortgage calculator',
      'hom12: How do closing costs impact the interest rate?',
      'hom13: Compare an interest-only vs. traditional mortgage',
      'hom14: How much can I borrow from my home equity (HELOC)?',
      'hom15: Loan comparison calculator',
      'hom16: How does a reverse mortgage work?',
      'hom20: Balloon mortgage calculator',
    ],
  },
  {
    type: 'insurance',
    details: [
      'ins01: How much life insurance do I need?',
      'ins02: What is my life expectancy?',
      'ins03: What are my needs for burial and final expenses?',
      'ins04: How much disability income insurance do I need?',
      'ins05: What are the chances of becoming disabled?',
      'ins06: What are my long-term care insurance needs?',
      'ins07: How much will I earn in my lifetime?',
      'ins08: What are the tax advantages of an annuity?',
      'ins09: How long will my current life insurance proceeds last?',
      'ins10: What is the future value of an annuity?',
      'ins11: Which is better, comprehensive plan or high-deductible plan with HSA?',
      'ins12: Compare a taxable investment to a tax-deferred investment',
      'ins13: What are the payments on an immediate annuity?',
    ],
  },
  {
    type: 'investment',
    details: [
      'inv01: How should I allocate my assets?',
      'inv02: Compare taxable vs. tax-free investment return',
      'inv03: What is the value of a bond?',
      'inv04: What is the return on my real estate investment?',
      'inv05: What is the value of compound interest?',
      'inv06: What is the value of a call or put option?',
      'inv07: Taxable vs. tax-advantaged savings?',
      'inv08: What is my risk tolerance?',
      'inv09: What is the long-term impact of increased investment return?',
      'inv10: Certificate of deposit (CD) analyzer',
      'inv11: What is the dividend yield on a stock?',
      'inv12: How do expenses impact mutual fund returns?',
      'inv13: Certificate of deposit (CD) laddering strategy',
      'inv14: Share Certificate analyzer',
      'inv15: Share Certificate laddering strategy',
      'inv16: What return do I need to get back to where I started?',
    ],
  },
  {
    type: 'paycheckandbenefits',
    details: [
      'pay03: Convert my salary to an equivalent hourly wage',
      'pay04: Convert my hourly wage to an equivalent annual salary',
      'pay05: What is the future value of my employee stock options?',
      "pay06: Should I exercise my 'in-the-money' stock options?",
      'pay07: What may my 401(k) be worth?',
      'pay08: What is the impact of increasing my 401(k) contribution?',
      'pay09: What may my 457(b) be worth?',
      'pay10: What is the impact of increasing my 457(b) contribution?',
      'pay11: What may my 403(b) Plan be worth?',
      'pay12: What is the impact of increasing my 403(b) contribution?',
      'pay13: What may my Health Savings Account (HSA) be worth?',
      'pay14: What may my company ESOP be worth?',
      'pay15: Should I contribute more to receive the maximum 401(k) match?',
    ],
  },
  {
    type: 'qualifiedplans',
    details: [
      'qua01: Evaluate my company pension payout options',
      'qua02: How much can I contribute to an IRA?',
      'qua03: How much retirement income may an IRA provide?',
      'qua04: Should I convert to a Roth IRA?',
      'qua05: What will my qualified plan(s) be worth at retirement?',
      'qua06: What is my current year required minimum distribution?',
      'qua07: What is my projected required minimum distributions?',
      'qua08: What are my lump sum distribution options?',
      'qua09: How do I maximize my employer 401(k) match?',
      'qua10: What is the impact of borrowing from my retirement plan?',
      'qua11: What is the impact of early withdrawal from my 401(k)?',
      "qua12: I'm self-employed, how much can I contribute to a retirement plan?",
      'qua13: Net unrealized appreciation (NUA) vs. IRA rollover?',
      'qua14: What are my Stretch IRA distributions?',
      'qua15: 72(t) early distribution analysis',
    ],
  },
  {
    type: 'retirement',
    details: [
      'ret01: How will retirement impact my living expenses?',
      'ret02: How much will I need to save for retirement?',
      'ret03: Are my current retirement savings sufficient?',
      'ret04: Social security retirement income estimator',
      'ret05: How does inflation impact my retirement income needs?',
      "ret06: I'm retired, how long will my savings last?",
      'ret07: When should I begin saving for retirement?',
      'ret08: Should I convert discretionary expenses to savings?',
      'ret09: How much retirement income may my 401(k) provide?',
      'ret10: Compare a Roth 401(k) to a Traditional 401(K)',
      'ret11: What is the value of catch-up contributions for retirement?',
    ],
  },
  {
    type: 'savings',
    details: [
      'sav01: Becoming a millionaire',
      'sav02: Income generated by a savings plan',
      'sav03: How long will it take to double my savings?',
      'sav04: How long until my savings reach my goal?',
      'sav05: Save now vs. save later',
      'sav06: How much should I save to reach my goal?',
      'sav07: What could my current savings grow to?',
      'sav08: Calculate rate of return',
      'sav09: How do taxes and inflation impact my investment return?',
      'sav10: What is the effective annual yield on my investment?',
      'sav11: What could my recurring (monthly) savings grow to?',
      'sav12: Is my savings plan on track?',
      'sav13: How long would I need to work to buy something?',
    ],
  },
  {
    type: 'taxation',
    details: [
      'inc01: What is my potential estate tax liability?',
      'inc02: 2022 federal income tax calculator',
      'inc03: Should I adjust my payroll withholdings?',
      'inc04: Will my investment interest be deductible?',
      'inc05: How much self-employment tax will I pay?',
      'inc06: Capital gains (losses) tax estimator',
      'inc07: Compare taxable, tax-deferred, and tax-free investment growth',
      'inc08: How much of my social security benefit may be taxed?',
      'inc09: What are the tax implications of paying interest?',
      'inc10: Should I itemize or take the standard deduction?',
      'inc11: What is my tax-equivalent yield?',
      'inc12: 2022 tax refund estimator',
      'inc13: Tax freedom day',
    ],
  },
  {
    type: 'other',
    details: [
      'cus35: Add, Subtract, Multiply, Divide',
      'tax01: Tax Bracket Calculator',
      'cus65: Profit Payout Calculator',
      'cus106: How soon can I breakeven if I refinance?',
      'cus107: VA Loan Refinance Comparison',
    ],
  },
];
