import React, { useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import aaImage from '../../assets/img/aa.webp';
import faImage from '../../assets/img/fa.webp';
import sherImage from '../../assets/img/sher.webp';
import { useTranslation } from 'react-i18next';
const styles = {
  container: {
    backgroundColor: 'white',
    py: { xs: 4, md: 10 },
  },
  imageWrapper: {
    display: { xs: 'none', md: 'block' },
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      margin: '10px auto',
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
  },
  titleLine: {
    width: '40px',
    height: '2px',
    bgcolor: 'secondary.main',
    mr: 1.5,
    display: 'inline-block',
  },
  name: {
    fontWeight: 300,
    my: 2,
  },
  qualification: {
    ml: 1,
    color: 'text.secondary',
    fontSize: '0.875rem',
  },
  details: {
    mb: 3,
    color: 'text.secondary',
    lineHeight: 1.6,
  },
  readMoreButton: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.875rem',
    color: 'primary.main',
    p: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'text.primary',
    },
  },
};

const Teams = () => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState<string | null>(null);

  const handleExpand = (id: string) => {
    setExpandedId(expandedId === id ? null : id);
  };
  return (
    <Box sx={styles.container}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {teamMembers.map((member) => (
            <Grid item xs={12} sm={6} md={4} key={member.id}>
              <Box>
                <Box sx={styles.imageWrapper}>
                  <img src={member.image} alt={member.name} loading="lazy" />
                </Box>

                <Box sx={{ lineHeight: 2 }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Box component="span" sx={styles.titleLine} />
                    <Typography variant="subtitle2" color="text.primary">
                      {t(member.position)}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="baseline">
                    <Typography variant="h3" sx={styles.name}>
                      {member.name}
                    </Typography>
                    <Typography variant="caption" sx={styles.qualification}>
                      {member.quali}
                    </Typography>
                  </Box>

                  <Typography variant="body1" sx={styles.details}>
                    {t(member.details)}
                  </Typography>

                  {expandedId === member.id && (
                    <Typography variant="body1" sx={styles.details}>
                      {t(member.more)}
                    </Typography>
                  )}

                  <Button
                    onClick={() => handleExpand(member.id)}
                    sx={styles.readMoreButton}
                  >
                    {expandedId === member.id
                      ? `${t('readless')} ←`
                      : `${t('readmore')} →`}
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Teams;
const teamMembers = [
  {
    id: '1',
    name: 'Adeola Ayanwale',
    quali: 'MBA, EA',
    position: 'teams.member1.position',
    image: aaImage,
    details: 'teams.member1.details',
    more: 'teams.member1.more',
  },
  {
    id: '2',
    name: 'Francis A.',
    position: 'teams.member2.position',
    image: faImage,
    details: 'teams.member2.details',
    more: 'teams.member2.more',
  },
  {
    id: '3',
    name: 'Sher Sori',
    position: 'teams.member3.position',
    image: sherImage,
    details: 'teams.member3.details',
    more: 'teams.member3.more',
  },
];
