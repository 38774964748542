import React, { useState } from 'react';
import { Box, Typography, Tab, Tabs, Container } from '@mui/material';
import Header from '../../components/layout/Header';
import { commonStyles, quoteStyles } from '../../assets/styles/home';
import Footer from '../../components/layout/Footer';
import CodePayment from '../../components/payment/CodePayment';
import ManualPayment from '../../components/payment/ManualPayment';
import MetaTags from '../../components/MetaTags';
import { defaultImageUrl, primaryImageUrl } from '../services/Quote';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`paneltab-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `paneltab-${index}`,
  };
}

const Payment = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <MetaTags
        title="A&E Financial Services - Payment"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={primaryImageUrl || defaultImageUrl}
      />
      <Header headerType="relative" />
      <Container maxWidth="lg">
        <Box sx={quoteStyles.card}>
          <Box sx={quoteStyles.content}>
            <Box sx={quoteStyles.title}>
              <Box sx={commonStyles.fullWidth}>
                <Box sx={quoteStyles.tabHead}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                  >
                    <Tab label="Code Payment" {...a11yProps(0)} />
                    <Tab label="Manual Payment" {...a11yProps(1)} />
                  </Tabs>
                </Box>
              </Box>
            </Box>
            <Box sx={commonStyles.fullWidth}>
              <TabPanel value={value} index={0}>
                <CodePayment />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ManualPayment />
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Payment;
