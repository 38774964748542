import LockPersonIcon from '@mui/icons-material/LockPerson';
import SendIcon from '@mui/icons-material/Send';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Header from '../components/layout/Header';
import { LOADING_SET } from '../context/Action';
import { Appcontext, AppDpx } from '../context/AppContext';
import { addClient, isExist } from '../store/api';
import { useTranslation } from 'react-i18next';
import MetaTags from '../components/MetaTags';
import { defaultImageUrl, primaryImageUrl } from '../views/services/Quote';
import { SignupFormData, tUser } from '../store/types';
import {
  AddLocation,
  CalendarMonth,
  CorporateFare,
  Email,
  Flag,
  LocationCity,
  Lock,
  Person,
  Phone,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/newuser',
    pageTitle: 'newuser',
  },
});
const defaultValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  cpassword: '',
  country: '',
  meta: {
    dob: '',
    country: 'USA',
    taxyear: '2025',
    street: '',
    city: '',
    state: '',
    zip: '',
    ssn: '',
    ein: '',
    company_name: '',
  },
};
const Newuser = () => {
  const { t } = useTranslation();
  const dispatch = useContext(AppDpx);
  const { loading } = useContext(Appcontext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { handleSubmit, control, reset, watch } = useForm<SignupFormData>({
    defaultValues,
  });
  const navigate = useNavigate();
  const [al, showAlert] = useState<{
    type: AlertColor;
    msg: string;
  } | null>(null);
  const _signup = async (data: tUser) => {
    data.role = 'aefclient';
    // date of birth format
    if (data?.meta?.dob) {
      const dob = data?.meta?.dob?.split('-');
      data.meta.dob = `${dob[1]}/${dob[2]}/${dob[0]}`;
    }
    dispatch({ type: LOADING_SET, data: true });
    const d = await addClient(data);
    if (d.error) {
      showAlert({ type: 'error', msg: 'forms.newuser.errormsg' });
    } else {
      showAlert({
        type: 'success',
        msg: 'forms.newuser.successmsg',
      });
      reset(defaultValues);
    }
    dispatch({ type: LOADING_SET, data: false });
  };
  return (
    <>
      <MetaTags
        title="A&E Financial Services - Register. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={primaryImageUrl || defaultImageUrl}
      />
      <Header headerType="relative" />
      <Container maxWidth="md">
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 4,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              borderRadius: 2,
              width: '100%',
              background: 'white',
              boxShadow: (theme) =>
                `0 8px 24px ${theme.palette.primary.light}25`,
            }}
          >
            {/* Header Section */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 4,
              }}
            >
              <Avatar
                sx={{
                  width: 56,
                  height: 56,
                  bgcolor: 'primary.main',
                  mb: 2,
                }}
              >
                <LockPersonIcon />
              </Avatar>
              <Typography
                variant="h5"
                color="primary.dark"
                fontWeight="500"
                textAlign="center"
              >
                {t('forms.newuser.title')}
              </Typography>
            </Box>

            {loading && <LinearProgress sx={{ mb: 3 }} />}

            {al && (
              <Alert
                severity={al?.type}
                onClose={() => showAlert(null)}
                sx={{ mb: 3 }}
              >
                {t(al?.msg)}
              </Alert>
            )}

            {/* Form Section */}
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2.5,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="firstname"
                    control={control}
                    rules={{ required: 'First name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.firstname')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="lastname"
                    control={control}
                    rules={{ required: 'Last name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.lastname')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                        message: t('forms.newuser.erroremail'),
                      },
                      validate: {
                        isExist: async (userEmail = 'test') => {
                          const emailExists = await isExist(userEmail);
                          return emailExists
                            ? (t('forms.newuser.taken') as string)
                            : true;
                        },
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        type="email"
                        label={t('forms.common.email')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\s*-?[0-9]{1,10}\s*$/,
                        message: t('forms.newuser.phoneauth'),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.phone')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        type={showPassword ? 'text' : 'password'}
                        label={t('forms.common.password')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock color="action" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="cpassword"
                    control={control}
                    rules={{
                      required: 'Please confirm your password',
                      validate: (val: string) => {
                        if (watch('password') !== val) {
                          return (
                            t('forms.newuser.matcherror') ||
                            'Passwords must match'
                          );
                        }
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        type={showConfirmPassword ? 'text' : 'password'}
                        label={t('forms.common.cpassword')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock color="action" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Controller
                name="meta.street"
                control={control}
                rules={{ required: 'Address is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    label={t('forms.common.street')}
                    fullWidth
                    helperText={error?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AddLocation color="action" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.city"
                    control={control}
                    rules={{ required: 'City is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.city')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CorporateFare color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.state"
                    control={control}
                    rules={{ required: 'State is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.state')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CorporateFare color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.country"
                    control={control}
                    rules={{ required: 'Country is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.country')}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Flag color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.ssn"
                    control={control}
                    rules={{
                      required: 'SSN is required',
                      maxLength: 4,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.ssn')}
                        fullWidth
                        type="number"
                        placeholder="Last 4 digits of SSN"
                        inputProps={{ maxLength: 4 }}
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationCity color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.company_name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.company_name')}
                        fullWidth
                        type="text"
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationCity color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.ein"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.ein')}
                        fullWidth
                        type="number"
                        placeholder="EIN"
                        inputProps={{ maxLength: 4 }}
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationCity color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.taxyear"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^(19|20)\d{2}$/,
                        message: 'Enter a valid year',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.taxyear')}
                        fullWidth
                        type="number"
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonth color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="meta.dob"
                    control={control}
                    rules={{
                      validate: (value) => {
                        const today = new Date();
                        const birthDate = new Date(value || '');
                        let age = today.getFullYear() - birthDate.getFullYear();
                        const monthDifference =
                          today.getMonth() - birthDate.getMonth();
                        if (
                          monthDifference < 0 ||
                          (monthDifference === 0 &&
                            today.getDate() < birthDate.getDate())
                        ) {
                          age--;
                        }
                        return age >= 10 || 'You must be at least 10 years old';
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        label={t('forms.common.dob')}
                        fullWidth
                        type="date"
                        helperText={error?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonth color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Button
                onClick={handleSubmit(_signup)}
                variant="contained"
                size="large"
                endIcon={<SendIcon />}
                sx={{
                  mt: 2,
                  py: 1.5,
                  fontWeight: 500,
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  borderRadius: 1.5,
                }}
              >
                {t('forms.common.register')}
              </Button>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                <Button
                  onClick={() => navigate('/forgot')}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': { color: 'primary.main' },
                    textTransform: 'none',
                  }}
                >
                  {t('forms.common.forgot')}
                </Button>
                <Button
                  onClick={() => navigate('/admin/login')}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': { color: 'primary.main' },
                    textTransform: 'none',
                  }}
                >
                  {t('forms.common.login')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Newuser;
