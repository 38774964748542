import React from 'react';
import { Grid, Box, Typography, IconButton, useTheme } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

interface FooterSocialProps {
  t: (key: string) => string;
}

const FooterSocial: React.FC<FooterSocialProps> = ({ t }) => {
  const theme = useTheme();

  const styles = {
    wrapper: {
      py: 3,
      height: '100%',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      mb: 2,
    },
    line: {
      width: '24px',
      height: '2px',
      backgroundColor: 'primary.main',
      display: 'inline-block',
    },
    title: {
      fontWeight: 500,
      color: 'text.primary',
    },
    description: {
      color: 'text.secondary',
      mb: 3,
      lineHeight: 1.6,
    },
    socialsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    socialButton: {
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        color: theme.palette.primary.main,
      },
    },
    icon: {
      fontSize: '28px',
    },
  };

  const socialLinks = [
    {
      icon: FacebookIcon,
      href: 'https://www.facebook.com/aefinancialservicesllc',
      label: 'Facebook',
    },
    {
      icon: LinkedInIcon,
      href: 'https://www.linkedin.com/in/aefinancialservices',
      label: 'LinkedIn',
    },
    {
      icon: TwitterIcon,
      href: 'https://www.twitter.com/essloffice',
      label: 'Twitter',
    },
    {
      icon: InstagramIcon,
      href: 'https://www.instagram.com/aefinancialservices',
      label: 'Instagram',
    },
  ];

  return (
    <Grid item xs={12} md={3}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.titleContainer}>
          <Box component="span" sx={styles.line} />
          <Typography variant="subtitle1" sx={styles.title}>
            {t('footer.column3.title')}
          </Typography>
        </Box>

        <Typography variant="body2" sx={styles.description}>
          {t('footer.column3.intro')}
        </Typography>

        <Box sx={styles.socialsContainer}>
          {socialLinks.map((social) => (
            <IconButton
              key={social.label}
              component="a"
              href={social.href}
              target="_blank"
              rel="noreferrer"
              aria-label={social.label}
              sx={styles.socialButton}
              color="inherit"
            >
              <social.icon sx={styles.icon} />
            </IconButton>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default FooterSocial;
