import React from 'react';
import {
  framerStyles,
  heroStyles,
  offerStyles,
} from '../../assets/styles/home';
import { Box, Grid, Container, Divider, Typography } from '@mui/material';
import DoneCircle from '../../assets/svg/done-circle';
import PrecisionCircle from '../../assets/svg/precision-circle';
import IRSCircle from '../../assets/svg/irs-circle';
import TaxcutCircle from '../../assets/svg/taxcut-circle';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import alarmClockIcon from '../../assets/img/alarm-clock.webp';
import calculationIcon from '../../assets/img/calculation.webp';
import bankIcon from '../../assets/img/bank.webp';
import taxcutIcon from '../../assets/img/taxcut.webp';

const Results = () => {
  const { t } = useTranslation();
  return (
    <Box component="div" sx={offerStyles.bg}>
      <Container maxWidth="lg" sx={offerStyles.offerContainer}>
        <Box sx={offerStyles.offerHeader}>
          <Typography variant="h6" sx={heroStyles.flex}>
            <Box component="span" sx={heroStyles.line} />
            {t('results.first.title')}
          </Typography>
          <motion.div
            className="box"
            initial="hidden"
            variants={framerStyles.variants}
            whileInView={framerStyles.resultsInView}
          >
            <Typography
              variant="h1"
              sx={[heroStyles.lightText, offerStyles.marginVertical]}
            >
              {t('results.first.intro')}
            </Typography>
          </motion.div>
          <Typography variant="subtitle1">
            {t('results.first.description')}
          </Typography>
        </Box>
        <Divider />
        <Box component="div" sx={heroStyles.marginVertical_1}>
          <Grid container spacing={2}>
            {resultData.map((items, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div variants={framerStyles.resultVariants}>
                    <ResultCard items={items} />
                  </motion.div>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Results;

interface ResultCardProps {
  items: {
    id: number;
    icon: string;
    title: string;
    details: string;
    progress: JSX.Element;
  };
}

const ResultCard = (props: ResultCardProps) => {
  const { items } = props;
  const { t } = useTranslation();
  return (
    <Box component="div" sx={offerStyles.resultCard}>
      <Box sx={offerStyles.resultCardContainer}>
        {items.progress}
        <img
          src={items.icon}
          alt="bookkeeping"
          style={offerStyles.resultCardImg as React.CSSProperties | undefined}
        />
      </Box>
      <Box component="div">
        <Typography
          variant="h5"
          sx={[offerStyles.offerTitle, offerStyles.marginVertical]}
        >
          {t(items.title)}
        </Typography>
        <Typography variant="body1" sx={offerStyles.offerText}>
          {t(items.details)}
        </Typography>
      </Box>
    </Box>
  );
};

const resultData = [
  {
    id: 1,
    icon: alarmClockIcon,
    title: 'results.second.card1.title',
    details: 'results.second.card1.details',
    progress: <DoneCircle />,
  },
  {
    id: 2,
    icon: calculationIcon,
    title: 'results.second.card2.title',
    details: 'results.second.card2.details',
    progress: <PrecisionCircle />,
  },
  {
    id: 3,
    icon: bankIcon,
    title: 'results.second.card3.title',
    details: 'results.second.card3.details',
    progress: <IRSCircle />,
  },
  {
    id: 4,
    icon: taxcutIcon,
    title: 'results.second.card4.title',
    details: 'results.second.card4.details',
    progress: <TaxcutCircle />,
  },
];
