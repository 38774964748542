import { loadStripe } from '@stripe/stripe-js';
import { path, essl, horaceapi } from './settings';
import {
  tCard,
  tContact,
  tLogin,
  tUser,
  tBlogInput,
  DocDto,
  UserDto,
  tInsurance,
} from './types';
import { Service } from '../views/portal/ServicesPage';
const authKey = process.env.REACT_APP_auth;
export const auth = {
  headers: {
    Authorization: `Basic ${authKey}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
export const PostSettings = <T>(obj: T) => {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
    body: JSON.stringify(obj),
  };
};
export const DeleteSettings = <T>(obj: T) => {
  return {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
    body: JSON.stringify(obj),
  };
};
export const PutSettings = <T>(obj: T) => {
  return {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
    body: JSON.stringify(obj),
  };
};

export async function addTicket(obj: tContact) {
  const response = await fetch(`${path}user/ticket/new`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
}
export async function doLogin(obj: tLogin) {
  try {
    const response = await fetch(`${path}user/aef/login`, PostSettings(obj));
    if (!response.ok) {
      return { error: { code: response.status } };
    }
    const data = await response.json();
    return { data };
  } catch (error) {
    return { error };
  }
}
export const userSignup = async (obj: tUser) => {
  const response = await fetch(`${path}user/signup`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const addClient = async (obj: tUser) => {
  const response = await fetch(`${path}user/aef/client/add`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};

export const editClient = async (obj: tUser) => {
  const response = await fetch(`${path}user/aef/client/edit`, PutSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const aefClients = async () => {
  const response = await fetch(`${path}user/aef/clients`, auth);
  return response.json();
};
export const myUploadedDocs = async (user: string) => {
  const response = await fetch(`${path}aef/doc/my?userID=${user}`, auth);
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  return response.json();
};
export const addDoc = async (obj: DocDto) => {
  const response = await fetch(`${path}aef/doc/add`, PostSettings(obj));
  return response.json();
};
export const isExist = async (em: string) => {
  const response = await fetch(`${path}user/exists/${em}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
  });
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return data;
};
export async function doToken(obj: UserDto) {
  const response = await fetch(`${path}user/dotoken`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.text();
  return { data };
}
export async function resetPass(obj: UserDto) {
  const response = await fetch(`${path}user/reset/password`, PostSettings(obj));
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.text();
  return { data };
}
export async function deleteDoc(id: string) {
  const response = await fetch(`${path}aef/doc/del?id=${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
  });
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
}
export async function deleteS3Object(key: string) {
  const resp = await fetch(`${path}aef/s3/del?key=${key}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
  });
  if (!resp.ok) {
    return { error: { code: resp.status } };
  }
  const data = await resp.json();
  return { data };
}
export async function deleteS3AndDb(key: string, id: string) {
  const resp = await fetch(`${path}aef/object/del?key=${key}&id=${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${authKey}`,
    },
  });
  if (!resp.ok) {
    return { error: { code: resp.status } };
  }
  const data = await resp.json();
  return { data };
}
export async function insuranceApi(obj: tInsurance) {
  const response = await fetch(`${path}insurance/add`, PostSettings(obj));
  return response.json();
}

export async function insuranceAll() {
  const response = await fetch(`${path}insurance/all`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export async function addCost(obj: tInsurance) {
  const response = await fetch(`${path}insurance/cost`, PutSettings(obj));
  return response.json();
}

export async function getPayment(id: number) {
  const response = await fetch(`${path}insurance/code/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}
export async function paymentIntentApi(obj: Record<string, unknown>) {
  const response = await fetch(`${essl}pay/intent`, PostSettings(obj));
  return response.json();
}
export const handlePay = async (obj: tCard) => {
  const apikey = process.env.REACT_APP_stripe_pub || '';
  const stripeInit = loadStripe(apikey);
  const str = await stripeInit;
  const resp = await fetch(`${path}pay/session`, PostSettings(obj));
  const session = await resp.json();
  const res = await str?.redirectToCheckout({
    sessionId: session.id,
  });
  if (res?.error) {
    console.error(res.error, ' what happened to stripe? ');
  }
  return res;
};

export const addNewBlog = async (obj: tBlogInput) => {
  const response = await fetch(
    `${horaceapi}blog/add?schema=dfm`,
    PostSettings(obj)
  );
  return response.json();
};

export const getBlogById = async (id: string) => {
  const response = await fetch(
    `${horaceapi}blog/${id}?app=aefinance&schema=dfm`
  );
  return response.json();
};

export const getAllBlogsByApp = async (app: string) => {
  const response = await fetch(
    `${horaceapi}blog/app/${app}?app=aefinance&schema=dfm`
  );
  return response.json();
};

export const getBlogByTitle = async (title: string) => {
  const response = await fetch(
    `${horaceapi}blog/title/${title}?app=aefinance&schema=dfm`
  );
  return response.json();
};
export const getAllServices = async () => {
  const response = await fetch(`${path}service/all`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};
export const addService = async (obj: Service) => {
  const response = await fetch(`${path}service/add`, PostSettings(obj));
  return response.json();
};
export const addNewsletter = async (obj: { email: string }) => {
  const response = await fetch(`${path}newsletter/add`, PostSettings(obj));
  return response.json();
};
export const getNewsletter = async () => {
  const response = await fetch(`${path}newsletter/all`, auth);
  return response.json();
};
