import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import TagManager from 'react-gtm-module';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MetaTags from '../../components/MetaTags';
import Map from '../../components/home/Map';
import Footer from '../../components/layout/Footer';
import Header from '../../components/layout/Header';
import { LOADING_SET } from '../../context/Action';
import { AppDpx, Appcontext } from '../../context/AppContext';
import { addTicket } from '../../store/api';
import { defaultImageUrl, primaryImageUrl } from '../services/Quote';
import { tContact } from '../../store/types';
import { Email, Person, Phone } from '@mui/icons-material';

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/contact',
    pageTitle: 'contact',
  },
});

const Contact = () => {
  const { t } = useTranslation();
  const [al, showAlert] = useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm<tContact>();
  const dispatch = useContext(AppDpx);
  const { loading } = useContext(Appcontext);
  const _submit = async (data: tContact) => {
    dispatch({ type: LOADING_SET, data: true });
    const d = await addTicket(data);
    if (d) {
      showAlert(true);
    }
    dispatch({ type: LOADING_SET, data: false });
    reset({ firstname: '', lastname: '', email: '', phone: '', message: '' });
  };
  return (
    <div>
      <MetaTags
        title="A&E Financial Services - Contact. Read | taxes | processes"
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={primaryImageUrl || defaultImageUrl}
      />
      <Header headerType="relative" />
      <Box component="div" mb={6}>
        <Map />
        <Container maxWidth="lg" sx={{ marginTop: 10, overflowX: 'hidden' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  height: '100%',
                  background:
                    'linear-gradient(145deg, primary.main, primary.dark)',
                  color: 'green',
                }}
              >
                <Typography variant="h5" gutterBottom fontWeight="500">
                  {t('contactForm.hours')}
                </Typography>
                <List sx={{ mt: 3 }}>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <BusinessIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Monday to Saturday"
                      secondary="9am to 5pm"
                      secondaryTypographyProps={{
                        color: 'secondary.main',
                      }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <PhoneIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Call us"
                      secondary="832-831-3907"
                      secondaryTypographyProps={{
                        color: 'secondary.main',
                      }}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          color="inherit"
                          href="mailto:info@aefinancialservicesllc.com"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ textDecoration: 'none' }}
                        >
                          {t('contactForm.mail')}
                        </Link>
                      }
                      secondary="info@aefinancialservicesllc.com"
                      secondaryTypographyProps={{
                        color: 'secondary.main',
                      }}
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {' '}
                <Typography variant="h5" gutterBottom fontWeight="500">
                  {t('contactForm.touch')}
                </Typography>
                {loading && (
                  <LinearProgress
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                )}
                <Fade in={al}>
                  <Box sx={{ mb: 3 }}>
                    <Alert
                      severity="success"
                      onClose={() => showAlert(false)}
                      sx={{ '& .MuiAlert-message': { width: '100%' } }}
                    >
                      {t('contactForm.alert')}
                    </Alert>
                  </Box>
                </Fade>
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="firstname"
                        control={control}
                        rules={{ required: 'First name is required' }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            label={t('contactForm.firstname')}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person color="action" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="lastname"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            label={t('contactForm.lastname')}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person color="action" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Email is required',
                          pattern: {
                            value:
                              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                            message: t('forms.newuser.erroremail'),
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            type="email"
                            label={t('contactForm.email')}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Email color="action" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="phone"
                        control={control}
                        rules={{
                          pattern: {
                            value: /^\s*-?[0-9]{1,10}\s*$/,
                            message: t('forms.newuser.phoneauth'),
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            label={t('contactForm.phone')}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Phone color="action" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="message"
                        control={control}
                        rules={{ required: 'Message is required' }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            multiline
                            rows={4}
                            label={t('contactForm.message')}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MessageIcon color="action" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleSubmit(_submit)}
                    disabled={loading}
                    endIcon={<SendIcon />}
                    sx={{
                      mt: 4,
                      py: 1.5,
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      borderRadius: 2,
                    }}
                  >
                    {loading ? 'Sending...' : t('contactForm.submit')}
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </div>
  );
};

export default Contact;
