import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  TextField,
  Grid,
} from '@mui/material';
import S3 from 'react-aws-s3';
import {
  CloudUpload,
  Delete,
  Description,
  Image,
  PictureAsPdf,
  Send,
} from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  addDoc,
  deleteDoc,
  deleteS3Object,
  getAllServices,
  myUploadedDocs,
} from '../../store/api';
import { useDropzone } from 'react-dropzone';
import './dropzone.css';
import { DocumentMetadata, UploadFile } from '../../views/documents/AddDoc';
import { Appcontext } from '../../context/AppContext';
import { S3_CONFIG } from '../../views/portal/NewBlog';
import useAlert from '../../hooks/useAlert';
import { DocResponse } from '../../store/types';

interface Service {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  createdOn: string;
  modifiedOn: string;
  offerings: string[];
  checklist: string[];
}

export interface UploadStatus {
  serviceId: string;
  checklistItem: string;
  status: 'pending' | 'submitted' | 'rejected';
  submittedAt?: string;
  fileName?: string;
  comments?: string;
}
const s3Client = new S3(S3_CONFIG);
interface UploadProps {
  serviceId?: string;
  updateServiceID?: (id: string) => void;
}

const DocumentUpload: React.FC<UploadProps> = ({
  serviceId,
  updateServiceID,
}) => {
  const { user } = useContext(Appcontext);
  const [selectedService, setSelectedService] = useState<string>(
    serviceId || ''
  );
  const [files, setFiles] = useState<UploadFile[]>([]);
  const { alertDisplay, setAlert } = useAlert();

  const [selectedChecklistItem, setSelectedChecklistItem] =
    useState<string>('');
  const [comment, setComment] = useState<string>('');

  const queryClient = useQueryClient();
  const { data: services = [], isLoading } = useQuery<Service[]>(
    'services',
    getAllServices,
    {
      onSuccess: (data) => {
        if (serviceId && !data.find((s) => s.id === serviceId)) {
          if (updateServiceID) {
            updateServiceID('');
          }
          setSelectedService('');
        } else if (!selectedService && data.length > 0) {
          setSelectedService(data[0].id);
          if (updateServiceID) {
            updateServiceID(data[0].id);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (serviceId && services.length > 0) {
      // Check if the serviceId is valid
      const validService = services.find((s) => s.id === serviceId);
      if (validService) {
        setSelectedService(serviceId);
      } else {
        if (updateServiceID) {
          updateServiceID('');
        }
        setSelectedService('');
      }
    }
  }, [serviceId, services, updateServiceID]);

  const updateStatusMutation = useMutation(addDoc, {
    onSuccess: () => {
      queryClient.invalidateQueries(['my-docs', user?.id]);
      setAlert({
        open: true,
        type: 'success',
        message: 'Document uploaded successfully',
      });
    },
  });
  const deleteMutation = useMutation(deleteDoc, {
    onSuccess: () => {
      queryClient.invalidateQueries(['my-docs', user?.id]);
    },
  });
  const { data: myDocs = [] } = useQuery({
    queryKey: ['my-docs', user?.id],
    queryFn: () => myUploadedDocs(user?.id as string),
    enabled: !!user?.id,
  });
  const removeFile = async (fileToRemove: File) => {
    setFiles(files.filter((file) => file.name !== fileToRemove.name));
    try {
      await deleteS3Object(`clientdocs/${fileToRemove.name}`);
      setAlert({
        open: true,
        type: 'success',
        message: 'File deleted successfully',
      });
    } catch (error) {
      setAlert({
        open: true,
        type: 'error',
        message: 'Failed to delete file. Please try again.',
      });
      throw new Error(error as string);
    }
  };

  const activeService = services.find((s) => s.id === selectedService);

  const handleServiceChange = (event: SelectChangeEvent) => {
    setSelectedService(event.target.value);
    setSelectedChecklistItem('');
    if (updateServiceID) {
      updateServiceID(event.target.value);
    }
  };

  const handleChecklistItemChange = (event: SelectChangeEvent) => {
    setSelectedChecklistItem(event.target.value);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        for (const file of acceptedFiles) {
          const filename = `clientdocs/${file.name}`;
          await s3Client.uploadFile(file, filename);
          setAlert({
            open: true,
            type: 'success',
            message: 'File uploaded successfully',
          });
        }
        setFiles((prev) => [...prev, ...acceptedFiles]);
      } catch (error) {
        setAlert({
          open: true,
          type: 'error',
          message: 'Failed to upload file. Please try again.',
        });
        throw new Error(error as string);
      }
    },
    [setAlert]
  );

  const handleUpload = async () => {
    if (!selectedService || !selectedChecklistItem || !user) return;
    const service = services.find((s) => s.id === selectedService);

    const documentData: DocumentMetadata = {
      title: selectedChecklistItem,
      userId: user?.id || '',
      service: service?.title || '',
      filepaths: files.map((f) => `clientdocs/${f.name}`),
      meta: {
        description: comment,
        uploadedBy: user?.email || '',
      },
    };
    await updateStatusMutation.mutateAsync(documentData);
    setComment('');
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/msword': ['.doc', '.docx'],
    },
    maxSize: 5242880, // 5MB
    maxFiles: 5,
  });

  const handleDelete = async (id: string) => {
    deleteMutation.mutateAsync(id);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'submitted':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };
  const getFileIcon = (fileType: string) => {
    if (fileType.includes('pdf')) {
      return <PictureAsPdf sx={{ fontSize: 40 }} color="error" />;
    } else if (fileType.includes('image')) {
      return <Image sx={{ fontSize: 40 }} color="primary" />;
    }
    return <Description sx={{ fontSize: 40 }} color="info" />;
  };

  return (
    <Container maxWidth="md">
      <Card>
        {alertDisplay()}
        <CardHeader title="Document Upload" />
        <CardContent>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              <Box display="flex" flexDirection="column" gap={3}>
                {/* Service Selection */}
                <FormControl fullWidth>
                  <InputLabel>Select Service</InputLabel>
                  <Select
                    value={selectedService}
                    onChange={handleServiceChange}
                    label="Select Service"
                  >
                    {services.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Checklist Item Selection */}
                {selectedService && (
                  <FormControl fullWidth>
                    <InputLabel>Select Document Type</InputLabel>
                    <Select
                      value={selectedChecklistItem}
                      onChange={handleChecklistItemChange}
                      label="Select Document Type"
                    >
                      {activeService?.checklist.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* Upload Section */}
                {selectedChecklistItem && (
                  <Box>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      label="Comments"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      margin="normal"
                    />
                    <Box className="drag-and-drop-wrap" sx={{ my: 2 }}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <CloudUpload sx={{ fontSize: 40, mb: 1 }} />
                        <Typography>
                          {isDragActive
                            ? 'Drop files here...'
                            : 'Drag and drop files, or click to select'}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Max 5 files, 5MB each (PDF, Word, Images)
                        </Typography>
                      </div>
                    </Box>
                    <Button
                      variant="contained"
                      fullWidth
                      //   disabled={
                      //     !formData.title || files.length === 0 || isLoading
                      //   }
                      endIcon={<Send />}
                      onClick={handleUpload}
                      sx={{ mt: 2 }}
                    >
                      Submit
                    </Button>
                  </Box>
                )}
              </Box>

              {/* Grid Layout for File Preview */}
              {files.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Dropped Files:
                  </Typography>
                  <Grid container spacing={2}>
                    {files.map((file, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={`${file.name}-${index}`}
                      >
                        <Card
                          sx={{
                            p: 2,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                          }}
                        >
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => removeFile(file)}
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                            }}
                          >
                            <Delete />
                          </IconButton>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mb={1}
                          >
                            {getFileIcon(file.type)}
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              align="center"
                              sx={{
                                wordBreak: 'break-word',
                                mb: 1,
                              }}
                            >
                              {file.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              align="center"
                              display="block"
                            >
                              {(file.size / 1024 / 1024).toFixed(2)} MB
                            </Typography>
                          </Box>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}

              {/* Status Table */}
              <TableContainer component={Paper} sx={{ mt: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Service</TableCell>
                      <TableCell>Document Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Submitted Date</TableCell>
                      <TableCell>Comments</TableCell>
                      <TableCell>Files</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myDocs?.map((doc: DocResponse, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{doc?.service}</TableCell>
                          <TableCell>{doc.title}</TableCell>
                          <TableCell>
                            <Chip
                              label={'Submitted'}
                              color={getStatusColor('submitted')}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            {doc.createdOn &&
                              new Date(doc.createdOn).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {String(doc?.meta?.description)}
                          </TableCell>
                          <TableCell>
                            {doc.filepaths.map((file: string, i: number) => (
                              <Chip
                                key={i}
                                label={file.split('/').pop()}
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                              />
                            ))}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              onClick={() => doc.id && handleDelete(doc.id)}
                              size="small"
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {myDocs?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="body2" color="textSecondary">
                            No documents uploaded yet
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default DocumentUpload;
