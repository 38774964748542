import React from 'react';
import { Box, Typography } from '@mui/material';
import { offerStyles } from '../../assets/styles/home';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { FC } from 'react';

interface OfferCardProps {
  items: {
    image: string;
    title: string;
    details: string;
    email?: string;
  };
}

const OfferCard: FC<OfferCardProps> = ({ items }) => {
  const { t } = useTranslation();
  return (
    <Box component="div" sx={offerStyles.offerCard}>
      <motion.div
        className="box"
        whileInView={{
          rotate: 360,
          transition: { from: 0, duration: 2 },
        }}
      >
        <Box component="div" sx={offerStyles.offerIcon}>
          <img src={items.image} alt="icon" />
        </Box>
      </motion.div>
      <motion.div className="box">
        <Box>
          <Typography variant="h5" sx={offerStyles.offerTitle}>
            {t(items.title)}
          </Typography>
          {items.email ? (
            <Typography
              variant="body1"
              sx={[offerStyles.offerText, offerStyles.email]}
              onClick={() => window.open(`mailto:${items.email}`)}
            >
              {t(items.details)}
            </Typography>
          ) : (
            <Typography variant="body1" sx={offerStyles.offerText}>
              {t(items.details)}
            </Typography>
          )}
        </Box>
      </motion.div>
    </Box>
  );
};

export default OfferCard;
