import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { useTranslation } from 'react-i18next';
import naeaImage from '../../assets/img/naea-gns-120.gif';
import natpImage from '../../assets/img/natp-gns-120.gif';
import efileImage from '../../assets/img/efile-gns-120.gif';

import 'swiper/css';

const testimonialStyles = {
  bgImg: {
    maxWidth: '100%',
    color: 'white',
    boxShadow: '0px 4px 4px 0px #00000000,inset 0 0 0 1000px rgba(0,0,0,.5)',
    my: 2,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    paddingBottom: '2rem',
    textAlign: 'center',
  },
  avatar: {
    width: '6rem',
    height: 'auto',
  },
};

const Accreditation = () => {
  const { t } = useTranslation();
  return (
    <Box component="div">
      <Box component="div" sx={{ my: 5 }}>
        <Container maxWidth="lg">
          <Box component="div" sx={testimonialStyles.header}>
            <Typography variant="h3" sx={{ fontWeight: 300 }}>
              {t('accreditations.title')}
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 1 }}>
              {t('accreditations.subtitle')}
            </Typography>
          </Box>

          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {companies.map((item, index) => (
              <SwiperSlide key={index + item.id}>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={item.image}
                    alt="companies accreditation"
                    style={{ maxWidth: '200px' }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
    </Box>
  );
};

export default Accreditation;

const companies = [
  {
    id: 1,
    image: naeaImage,
  },
  {
    id: 2,
    image: natpImage,
  },
  {
    id: 3,
    image: efileImage,
  },
];
