import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { blogs, defaultAuthor, socials } from '../../assets/data/blogs';
import MetaTags from '../../components/MetaTags';
import Header from '../../components/layout/Header';
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GitHub,
  LinkedIn,
  Twitter,
  Link as LinkIcon,
} from '@mui/icons-material';
import FeaturedPost from './FeaturedPost';
import { useQuery } from 'react-query';
import { getBlogById } from '../../store/api';
import { AppEnum, tBlog, tBlogInput } from '../../store/types';
import placeholderAvatar from '../../assets/img/placeholder.png';
import { defaultImageUrl, primaryImageUrl } from '../services/Quote';
const classes = {
  root: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: 1,
    },
  },
  mainContent: {
    maxWidth: '800px',
    margin: '0 auto',
    px: { xs: 2, sm: 4 },
    py: { xs: 3, sm: 5 },
  },
  title: {
    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
    fontWeight: 700,
    mb: 2,
    lineHeight: 1.2,
  },
  brief: {
    fontSize: { xs: '1.25rem', sm: '1.5rem' },
    color: 'text.secondary',
    mb: 4,
    lineHeight: 1.4,
  },
  contentSection: {
    fontSize: { xs: '1rem', sm: '1.125rem' },
    lineHeight: 1.8,
    color: 'text.primary',
    my: 4,
    textAlign: { xs: 'left', sm: 'justify' },
  },
  authorBar: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 2,
    p: 2,
    mb: 4,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  socialLinks: {
    display: 'flex',
    gap: 1,
    '& a': {
      color: 'text.secondary',
      transition: 'color 0.2s',
      '&:hover': {
        color: 'primary.main',
      },
    },
  },
  ctaSection: {
    bgcolor: 'primary.main',
    color: 'white',
    p: 4,
    borderRadius: 2,
    textAlign: 'center',
    my: 6,
    '& a': {
      color: 'white',
      textDecoration: 'underline',
      fontWeight: 'bold',
      '&:hover': {
        color: 'secondary.light',
      },
    },
  },
  relatedPosts: {
    py: 6,
    bgcolor: 'grey.50',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
  },
};
const Mainpost = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<tBlogInput | null>(null);
  const { data, isLoading } = useQuery({
    queryKey: ['blogs', { id: params.id }],
    queryFn: () => getBlogById(params?.id as string),
  });
  const moreReads = blogs.filter((post) => post.id !== +params.id!);
  const readingTime = () => {
    const text = post?.details;
    const wpm = 225;
    const words = text?.trim().split(/\s+/).length;
    const time = words && Math.ceil(words / wpm);
    return time;
  };
  React.useEffect(() => {
    let localPost;
    if (data) {
      if (data.detail && data.detail[0].type === 'int_parsing')
        return navigate('/blogs');
      setPost(data);
      window.scrollTo(0, 0);
    } else {
      localPost = blogs.find((post) => post.id === +params.id!) as tBlog;
      if (localPost) {
        setPost({
          id: localPost.id || params.id,
          author: localPost?.author?.name || defaultAuthor.name,
          image: localPost.image,
          title: localPost.title,
          details: localPost.details,
          category: localPost.category,
          brief: localPost.brief,
          app: AppEnum.aefinance,
          bio: localPost?.author?.bio || '',
          tags: [''],
          userImage: localPost?.author?.image || placeholderAvatar,
          updatedAt: localPost.updatedAt,
        });
      }
      window.scrollTo(0, 0);
    }

    if (data === null && localPost === undefined) {
      navigate('/blogs');
    }
  }, [params?.id, data, navigate]);
  if (isLoading) {
    return (
      <Box sx={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={classes.root}>
      <MetaTags
        title={`A&E Financial Services - ${post?.title}. Read | taxes | processes`}
        description="Discover A&E Financial Services, your trusted source for personalized insurance, read taxes, and financial solutions, including expert tax services and efficient processes, catering to your various insurance needs 24/7."
        keywords="Income tax in Houston,Income tax near me,Income tax preparation in Houston,Tax office near Stafford,Tax office near 77036,Tax office near 77082,Tax office near 77099,Tax office near 77007,Tax office near 77479,Tax office near 77401,Tax office near 77489, Taxes, Financial, Financial Services, read, processes"
        url="https://www.aefinancialservicesllc.com"
        imageUrl={primaryImageUrl || defaultImageUrl}
      />
      <Header headerType="relative" />
      <Container maxWidth="lg">
        <Box sx={classes.mainContent}>
          {/* Author Bar */}
          <Box sx={classes.authorBar}>
            <Box sx={classes.authorInfo}>
              <Avatar
                alt={post?.author || 'AE Financial Services'}
                src={post?.userImage || placeholderAvatar}
              />
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {post?.author || 'AE Financial Services'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {post?.bio || 'AE Financial Services'} • {readingTime()} min
                  read
                </Typography>
              </Box>
            </Box>

            <Box sx={classes.socialLinks}>
              {Object.entries({ LinkedIn, Twitter, GitHub }).map(
                ([name, Icon]) => {
                  const socialName = name.toLowerCase() as keyof typeof socials;
                  return (
                    <Tooltip key={name} title={socialName}>
                      <Box
                        component="a"
                        href={`https://${socialName}.com/${socials[socialName]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon />
                      </Box>
                    </Tooltip>
                  );
                }
              )}
              <Tooltip title="Copy link">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    <Alert severity="info">Link Copied</Alert>;
                  }}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Main Content */}
          <Typography variant="h1" sx={classes.title}>
            {post?.title}
          </Typography>

          <Typography variant="h2" sx={classes.brief}>
            {post?.brief}
          </Typography>

          <Box
            component="img"
            src={post?.image || 'https://via.placeholder.com/800x400'}
            alt={post?.title}
          />

          <Box
            sx={classes.contentSection}
            dangerouslySetInnerHTML={{ __html: post?.details || '' }}
          />

          {/* CTA Section */}
          <Box sx={classes.ctaSection}>
            <Typography variant="h5" component="h3">
              Start a consultation with us today on{' '}
              <Link to="/contact" id="blog-click">
                {post?.title}
              </Link>
            </Typography>
          </Box>

          {/* Related Posts */}
          <Box sx={classes.relatedPosts}>
            <Typography variant="h6" gutterBottom>
              Related Articles
            </Typography>
            <Grid container spacing={4}>
              {moreReads.slice(0, 3).map((post, idx) => (
                <FeaturedPost key={idx} post={post} />
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Mainpost;
