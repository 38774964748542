import { useNavigate, useParams } from 'react-router-dom';
import DocumentUpload from '../../components/portal/DocumentUpload';
import Portal from '../portal/Portal';
// This component is used to upload a document to a service

const MakeDoc = () => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const updatePath = (newId: string) => {
    navigate(`/admin/docadd/${newId}`);
  };
  return (
    <Portal>
      <DocumentUpload serviceId={serviceId} updateServiceID={updatePath} />
    </Portal>
  );
};
export default MakeDoc;
