import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Appcontext, AppDpx } from '../context/AppContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { PROFILE_SET } from '../context/Action';

const IDLE_TIMEOUT = 10 * 60 * 1000; // 10 minutes
const WARNING_TIME = 1 * 60 * 1000; // 1 minute warning

const IdleTimeoutWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(Appcontext);
  const dispatch = useContext(AppDpx);
  const idleTimerRef = useRef<NodeJS.Timeout | null>(null);
  const warningTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [showWarning, setShowWarning] = useState(false);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('user');
    dispatch({ type: PROFILE_SET, data: null });
    navigate('/');
    setShowWarning(false);
  }, [navigate, dispatch]);

  const resetTimers = useCallback(() => {
    if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
    if (warningTimerRef.current) clearTimeout(warningTimerRef.current);

    warningTimerRef.current = setTimeout(() => {
      setShowWarning(true);
    }, IDLE_TIMEOUT - WARNING_TIME);

    idleTimerRef.current = setTimeout(handleLogout, IDLE_TIMEOUT);
  }, [handleLogout]);

  const continueSession = useCallback(() => {
    setShowWarning(false);
    resetTimers();
  }, [resetTimers]);

  useEffect(() => {
    if (!user?.id) return; // Only run if user is logged in

    const events = [
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
    ];

    const handleUserActivity = () => {
      if (!showWarning) {
        resetTimers();
      }
    };

    events.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    resetTimers();

    return () => {
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
      if (warningTimerRef.current) clearTimeout(warningTimerRef.current);
      events.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
    };
  }, [user, resetTimers, showWarning]);

  if (!user?.id) return <>{children}</>;

  return (
    <>
      {children}
      <Dialog open={showWarning}>
        <DialogTitle>Session Timeout Warning</DialogTitle>
        <DialogContent>
          Your session is about to expire due to inactivity. Would you like to
          continue?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="error">
            Logout
          </Button>
          <Button onClick={continueSession} color="primary" variant="contained">
            Continue Session
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default IdleTimeoutWrapper;
