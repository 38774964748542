import {
  Box,
  Typography,
  Container,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import PublicIcon from '@mui/icons-material/Public';
import mapImg from '../../assets/img/footer-map.webp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { aefinance } from '../../store/aefinance';
import { footerStyles } from '../../assets/styles/home';
import FooterLte from './Footerlte';
import FooterSocial from './FooterSocial';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        ...footerStyles.container,
        position: 'relative',
        width: '100%',
        bottom: 0,
        backgroundColor: 'background.paper',
        paddingBottom: '80px',
      }}
    >
      <Container maxWidth="xl">
        <Box px={8}>
          <Box component="div" mb={4}>
            <Grid container spacing={5}>
              <Grid item sm={12} md={3}>
                <Box component="div" sx={footerStyles.lineHeight}>
                  <Typography variant="body2" sx={footerStyles.flex}>
                    <Box component="span" sx={footerStyles.line} />
                    {aefinance.contact.name}
                  </Typography>

                  <Typography variant="h5" sx={footerStyles.lightText}>
                    {t('footer.column1.title')}
                  </Typography>
                  <Typography variant="body1" sx={footerStyles.details}>
                    {t('footer.column1.subtitle')}
                  </Typography>
                </Box>
                <Box component="div">
                  <IconButton
                    component="a"
                    href={`tel:${aefinance.contact.phone}`}
                  >
                    <Typography variant="body1" sx={footerStyles.contact}>
                      <CallIcon sx={footerStyles.icons} />
                      {aefinance.contact.phone}
                    </Typography>
                  </IconButton>
                  <IconButton
                    component="a"
                    href={`mailto:${aefinance.contact.email}`}
                  >
                    <Typography variant="body1" sx={footerStyles.contact}>
                      <MailIcon sx={footerStyles.icons} />
                      {aefinance.contact.emailText}
                    </Typography>
                  </IconButton>
                  <IconButton
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    href={aefinance.contact.website}
                  >
                    <Typography variant="body1" sx={footerStyles.contact}>
                      <PublicIcon sx={footerStyles.icons} />
                      {aefinance.contact.website}
                    </Typography>
                  </IconButton>
                </Box>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box component="div" sx={footerStyles.lineHeight}>
                  <Typography variant="body2" sx={footerStyles.flex}>
                    <Box component="span" sx={footerStyles.line} />
                    {t('footer.column2.title')}
                  </Typography>

                  <Typography variant="h5" sx={footerStyles.lightText}>
                    {t('footer.column2.subtitle')}
                  </Typography>
                </Box>
                <Box
                  component="div"
                  sx={[footerStyles.flex, footerStyles.responsive]}
                >
                  <img src={mapImg} alt="A simple map illustration" />
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <IconButton>
                      <Typography variant="body1" sx={footerStyles.contact}>
                        <LocationOnIcon sx={footerStyles.icons} />
                        United States
                      </Typography>
                    </IconButton>
                    <IconButton>
                      <Typography variant="body1" sx={footerStyles.contact}>
                        <LocationOnIcon sx={footerStyles.icons} />
                        {aefinance.contact.address}
                      </Typography>
                    </IconButton>
                    <IconButton>
                      <Typography variant="body1" sx={footerStyles.contact}>
                        <LocationOnIcon sx={footerStyles.icons} />
                        {aefinance.contact.address2}
                      </Typography>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <FooterSocial t={t} />
            </Grid>
          </Box>
          <Divider />
          <FooterLte />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
