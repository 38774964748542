import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from './assets/styles/themes';
import { Route, Routes } from 'react-router-dom';
import Home from './views/home/Home';
import ErrorPage from './views/404';
import Index from './views/portal/Index';
import Contact from './views/contact/Contact';
import Forgot from './user/Forgot';
import Newuser from './user/Newuser';
import Users from './views/portal/Users';
import Docs from './views/portal/Docs';
import Settings from './views/portal/Settings';
import Checkout from './views/portal/Checkout';
import Profile from './views/portal/Profile';
import Addclient from './views/portal/Addclient';
import { AppProvider } from './context/AppContext';
import Services from './views/services/Services';
import Quote from './views/services/Quote';
import Success from './components/stripe/Success';
import Failed from './components/stripe/Failed';
import Payment from './views/payment/Payment';
import QuoteList from './views/services/QuoteList';
import QuoteDetails from './views/services/QuoteDetails';
import Blogs from './views/blog/Blogs';
import Mainpost from './views/blog/Mainpost';
import NewBlog from './views/portal/NewBlog';
import ServicesPage from './views/portal/ServicesPage';
import MakeDoc from './views/documents/Makedoc';
import IdleTimeoutWrapper from './components/IdleTimeoutWrapper';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Calendly from './views/Calendar/Calendly';
import NewsletterSubscribers from './views/portal/Newsletters';
function Chat() {
  return (
    <div className="App">
      <TawkMessengerReact
        propertyId="63a9e525c2f1ac1e202a34ae"
        widgetId="1gl7qe70d"
      />
    </div>
  );
}
const queryClient = new QueryClient();
function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <IdleTimeoutWrapper>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="contact" element={<Contact />} />
              <Route path="admin/docadd/:serviceId?" element={<MakeDoc />} />
              <Route path="appointment" element={<Calendly />} />
              <Route path="admin/login" element={<Index />} />
              <Route path="forgot" element={<Forgot />} />
              <Route path="newuser" element={<Newuser />} />
              <Route path="admin/" element={<Docs />} />
              <Route path="users" element={<Users />} />
              <Route path="subscribers" element={<NewsletterSubscribers />} />
              <Route path="addclient" element={<Addclient />} />
              <Route path="settings" element={<Settings />} />
              <Route path="quote" element={<Quote />} />
              <Route path="allquotes" element={<QuoteList />} />
              <Route path="quote/detail" element={<QuoteDetails />} />
              <Route path="profile" element={<Profile />} />
              <Route path="services" element={<Services />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog/:id" element={<Mainpost />} />
              <Route path="/blog/new" element={<NewBlog />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="checkout/success" element={<Success />} />
              <Route path="checkout/failed" element={<Failed />} />
              <Route path="payment" element={<Payment />} />
              <Route path="admin/services" element={<ServicesPage />} />
              <Route path="error" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </IdleTimeoutWrapper>
        </AppProvider>
      </QueryClientProvider>
      <Chat />
    </ThemeProvider>
  );
}
export default App;
