import bgImg from "../img/home-bg.webp";
import bgForm from "../img/img-form.webp";
import bgRev from "../img/bgn-gradient.webp";
import { COLORS } from "./themes";

export const headerStyles = {
  fixed: {
    top: 0,
    left: 0,
  },
  imgContainer: {
    mr: 2,
    display: { xs: "none", md: "flex" },
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
  },
  imgContainerMobile: {
    mr: 2,
    display: { md: "flex" },
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    textDecoration: "none",
    my: 5,
  },
  imgMobile: { width: "100px" },
  flex: { display: "flex" },
  mr_1: { mr: 1 },
  mt_45: { mt: "45px" },
  menuItem: {
    borderRadius: 1,
    justifyContent: "flex-start",
    px: 3,
    textAlign: "left",
    textTransform: "none",
    width: "100%",
    backgroundColor: "transparent",
    color: "black",

    "&:hover": {
      backgroundColor: "primary.main",
    },
  },
  mobileBtn: { my: 2, display: "block" },
  menuList: { flexGrow: 1, display: { xs: "none", md: "flex" } },
  img: { maxWidth: "100px", maxHeight: "100px" },
  iconButton: { flexGrow: 1, display: { xs: "flex", md: "none" } },
  drawer: {
    display: { xs: "flex", md: "none" },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "neutral.900",
    color: "#FFFFFF",
    width: 280,
  },
  headerBtn: { color: "#333", display: "block", marginLeft: 1 },
  redBtn: {
    bgcolor: "#ff0000",
    color: "white",

    "&:hover": {
      bgcolor: "#ff3344",
    },
  },
  greenBtn: {
    bgcolor: "#008000",
    color: "white",
    ml: 1,

    "&:hover": {
      bgcolor: " #00cc00",
    },
  },
  modalContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    ml: "auto",
  },
};

export const homeStyles = {
  container: { position: "relative", overflowX: "hidden" },
};

export const heroStyles = {
  container: {
    "@media (max-width: 900px)": {
      minHeight: "50rem",
    },
  },
  marginVertical: { margin: "12px auto" },
  marginVertical_1: {
    margin: "50px auto",
  },
  header: {
    color: "white",
    marginLeft: "30rem",

    "@media (max-width: 900px)": {
      marginLeft: "auto",
    },
  },
  buttonCon: {
    marginTop: 3,
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 900px)": {
      flexDirection: "column",
    },
  },
  img: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  bgImg: {
    maxWidth: "100%",
    minHeight: "100vh",
    background: `url(${bgImg}) center center no-repeat`,
    backgroundSize: "cover",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  bodyFlex: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingTop: "10vh",
    paddingBottom: "10vh",
    minHeight: "100vh",

    "@media (max-width: 900px)": {
      justifyContent: "center",
      alignItems: "flex-start",
      minHeight: "auto",
      width: "100vw",
      paddingBottom: "10px",
      paddingTop: "15px",
    },
  },

  heroText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    padding: 3,
    wordBreak: "break-word",
    wordWrap: "break-word",

    "@media (max-width: 900px)": {
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "50px",
      textAlign: "left",
    },
  },
  lightText: {
    fontWeight: "light",
    fontSize: "3.5rem",
  },
  boldText: {
    color: "white",
    fontWeight: "900",
  },
  line: {
    width: "4.375rem",
    borderTop: "2px solid #A91867",
    marginTop: "-1px",
    marginRight: "10px",
    marginBottom: 0,
    display: "block",
    flexGrow: 0,

    "@media (max-width: 900px)": {
      width: "2.5rem",
    },
  },

  button1: {
    marginRight: "1rem",
    backgroundColor: "white",
    textAlign: "center",
    color: "primary.main",
    padding: ".8em 1.875em",
    borderRadius: "50px",
    "@media (max-width: 900px)": {
      marginButton: 3,
      mx: "auto",
      width: "80vw",
    },

    "&:hover": {
      transform: "scale(1.05)",

      backgroundColor: "primary.main",
      color: "white",
    },
  },

  button2: {
    padding: ".8em 1.875em",
    borderRadius: "50px",
    textAlign: "center",
    backgroundColor: "primary.main",
    color: "white",

    "@media (max-width: 900px)": {
      marginButton: 3,
      marginTop: 3,
      mx: "auto",
      width: "80vw",
    },

    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: "white",
      color: "primary.main",
    },
  },
};

export const teamStyles = {
  container: {
    backgroundColor: "white",
    padding: "5rem 0",
    "@media (max-width: 900px)": {
      padding: "2rem 0",
    },
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    margin: "10px auto",
  },
  line: {
    width: "2.5rem",
    borderTop: "2px solid #A91867",
    marginTop: "-1px",
    marginRight: "10px",
    marginBottom: 0,
    display: "block",
    flexGrow: 0,
  },
  lightText: {
    fontWeight: 300,
    margin: "1rem 0",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  details: {
    marginBottom: "1.5rem",
  },
  moreStyle: {
    textDecoration: "none",
    color: "primary.main",
    fontWeight: "bold",
    fontSize: ".725rem",

    "&:hover": {
      color: "black",
    },
  },
};

export const taxCardStyles = {
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4rem 1rem",
    backgroundColor: "aliceblue",
    boxShadow: "0px 4px 4px 0px #00000055",
  },
  cardIcon: {
    alignSelf: "flex-end",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
export const loginStyles = {
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8rem",

    "@media (max-width: 600px)": {
      marginTop: "2rem",
    },
  },
  form: {
    "& > :not(style)": { m: 1 },
    display: "flex",

    "@media (max-width: 600px)": {
      flexDirection: "column",
      marginRight: 2,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width: 600px)": {
      alignItems: "flex-start",
    },
  },

  rowButtons: {
    display: "flex",

    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  content: {
    width: "50%",
    borderLeft: "5px solid #A91867",
    borderRightColor: "coral",
    borderRadius: "2rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    boxShadow: "0px 4px 4px 0px #00000055",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "20px 10px",
      padding: "10px",
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "0.5rem",
    marginBottom: "1rem",
  },
};
export const portalStyles = {
  card: {
    display: "flex",
    height: "100vh",
  },
  sidebar: {
    width: "5.5rem",
    paddingTop: "5rem",
    backgroundColor: "primary.main",
    height: "100%",
    position: "fixed",
    zIndex: 1,
    overflowX: "hidden",
    overflowY: "auto",

    "@media (max-width: 700px)": {
      width: "3.5rem",
    },
  },
  workarea: {
    marginTop: "4.5rem",
    marginLeft: "10rem",
    width: "70%",

    "@media (max-width: 700px)": {
      width: "80%",
      margin: "4.5rem .5rem",
      marginLeft: "3.75rem",
    },
  },

  docbox: {
    borderLeft: "5px solid #A91867",
    borderRightColor: "primary.main",
    boxShadow: "0px 4px 4px 0px #00000055",
    marginRight: 2.5,
    marginBottom: 2,
    width: 280,
  },
  docaction: {
    display: "flex",
    justifyContent: "space-between",

    color: "primary.main",
    "a:link": {
      color: "primary.main",
    },
  },
};

export const offerStyles = {
  bg: {
    backgroundColor: "#f3f3f3",
  },
  email: {
    cursor: "pointer",
    color: "blue",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  offerContainer: { paddingTop: 5, paddingBottom: 5 },
  offerHeader: { maxWidth: "500px", paddingBottom: 5, paddingTop: 5 },
  offerCard: {
    display: "flex",
    alignItems: "center",
  },
  resultCard: {
    maxWidth: "300px",

    "@media (max-width: 900px)": {
      margin: "20px auto",
    },
  },
  resultCardContainer: {
    position: "relative",
    maxWidth: "150px",
    marginBottom: 3,
  },
  resultCardImg: {
    position: "absolute",
    top: 50,
    left: 45,
    width: "40%",
  },
  marginVertical: { margin: "10px auto" },
  offerIcon: {
    marginRight: "1rem",
  },
  offerTitle: {
    fontWeight: 400,
  },
  offerText: {
    wordWrap: "break-word",
    wordBreak: "break-word",
    fontWeight: 400,
  },
};
export const introStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100vh",
    maxWidth: "700px",
  },
  text: {
    color: COLORS.secondary.main,
    fontWeight: "bold",
    fontSize: "3rem",
    textAlign: "center",
    margin: "1rem 0",
  },
  details: {
    color: "black",
    fontSize: "1.5rem",
  },
};

export const reviewStyles = {
  bgImg: {
    width: "100%",
    minHeight: "50vh",
    background: `url(${bgRev}) center center no-repeat`,
    backgroundSize: "cover",
    boxShadow: "0px 4px 4px 0px #00000000,inset 0 0 0 1000px rgba(0,0,0,.5)",
  },
  img: {
    maxWidth: "80%",
    height: "auto",
  },
  name: {
    marginTop: 2,
    fontStyle: "italic",
    fontWeight: 500,
  },
  margin: { margin: "12px auto" },
  padding: { padding: "20px auto" },
  avatar: {
    width: "4rem",
    height: "auto",
    marginBottom: "1rem",
  },
  flexItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexItem: {
    zIndex: 10,
    flexWrap: "wrap",
    maxWidth: "700px",
    color: "white",

    "@media (max-width: 900px)": {
      maxWidth: "100%",
      margin: "6rem auto",
    },
  },
  flexItemImage: {
    zIndex: 10,

    "@media (max-width: 900px)": {
      display: "none",
    },
  },
};
export const sidebarStyles = {
  navbox: {
    fontSize: "3rem",
    "& > * ": {
      padding: "0.2rem 1.5rem",
      color: "white",
      height: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "white",

        color: "primary.main",
        transform: "translate(2px)",
      },
    },

    "@media (max-width: 600px)": {
      fontSize: "2rem",
      "& > * ": {
        padding: "0.2rem 0.8rem",
      },
    },
  },
  navItem: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
  },
  icon: {
    color: "white",
    fontSize: "2rem",
    "&:hover": {
      color: "primary.main",
    },
  },
};
export const txtFieldStyles = {
  txtBox: {
    "& > * ": {
      padding: 1,
    },
  },
};
export const calStyles = {
  appointment: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "6rem",
  },
};
export const contactStyles = {
  contactBox: {
    display: "flex",
    justifyContent: "spaceBetween",
    "& > *": {
      margin: 2,
    },

    "@media (max-width: 900px)": {
      flexDirection: "column",
      width: "100%",
      margin: "0 auto",
    },
  },
  infoBox: {
    width: "30%",
    boxShadow: "0px 4px 4px 0px #00000055",
    borderRadius: "2rem",
    padding: 2,

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "20px auto",
      overflow: "hidden",
      boxShadow: "none",
    },
  },

  formBox: {
    boxShadow: "0px 4px 4px 0px #00000055",
    borderRadius: "2rem",
    padding: 2,
    width: "100%",
    textAlign: "center",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "0 auto",
      boxShadow: "none",
    },
  },
  boxItem: {
    display: "flex",
    margin: 1,
    "& > *": {
      margin: 1.4,
    },

    "@media (max-width: 900px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  txtTitle: {
    letterSpacing: 1.5,
    color: "primary.dark",
    textAlign: "center",
  },
};

export const blogStyles = {
  container: {
    backgroundColor: "white",
    padding: "5rem 0",
    "@media (max-width: 900px)": {
      padding: "2rem 0",
    },
  },
  img: {
    maxwidth: "100%",
    height: "auto",
    margin: "10px auto",
  },
  line: {
    width: "2.5rem",
    borderTop: "2px solid #A91867",
    marginTop: "-1px",
    marginRight: "10px",
    marginBottom: 0,
    display: "block",
    flexGrow: 0,
  },
  title: {
    fontWeight: 300,
    margin: ".5rem 0",
    color: "secondary.main",
    cursor: "pointer",
    fontSize: "1.5rem",

    "&:hover": {
      color: "#333",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  details: {
    marginBottom: "1rem",
  },
  moreStyle: {
    textDecoration: "none",
    color: "secondary.main",
    fontWeight: "bold",
    fontSize: ".725rem",

    "&:hover": {
      color: "black",
    },
  },
};

export const quoteStyles = {
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2rem",
    my: "4rem",

    "@media (max-width: 600px)": {
      marginTop: "2rem",
    },
  },
  content: {
    width: "100%",
    borderLeft: "5px solid #A91867",
    borderRightColor: "coral",
    padding: "2rem",
    boxShadow: "0px 4px 4px 0px #00000055",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "20px 10px",
      padding: "10px",
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    my: "0.5rem",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width: 600px)": {
      alignItems: "flex-start",
    },
  },
  tabHead: { borderBottom: 1, borderColor: "divider" },
};

export const commonStyles = {
  formMargin: {
    "& > :not(style)": { m: 1 },
  },
  columnSpace: { xs: 0, sm: 1, md: 2 },
  buttonMargin: { mt: 3, mb: 2 },
  fullWidth: { width: "100%" },
};

export const framerStyles = {
  variants: { hidden: { opacity: 0, scale: 0.5 } },
  resultVariants: {
    offscreen: {
      x: 300,
    },
    onscreen: {
      x: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  },
  offerInView: {
    opacity: 1,
    scale: 1,
    transition: {
      default: {
        duration: 0.5,
        ease: "linear",
      },
      scale: {
        type: "spring",
        damping: 5,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  },
  resultsInView: {
    opacity: 1,
    scale: 1,
    transition: {
      default: {
        duration: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      },
      scale: {
        type: "spring",
        damping: 5,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  },
};

export const newsLetterStyles = {
  container: {
    //backgroundColor: 'secondary.main',
    backgroundColor: "primary.main",
    padding: "3rem 0",
    //my: 3,
  },
  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: 5,
    color: "white",

    "@media (max-width: 900px)": {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  form: {
    backgroundColor: "white",
    display: "flex",
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
  textField: {
    backgroundColor: "white",
    flex: 1,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
  button: {
    //backgroundColor: "primary.main",
    backgroundColor: COLORS.secondary.main,
    color: "white",
    height: "3.5rem",
    borderRadius: 0,
    margin: 0,

    "&:hover": {
      backgroundColor: COLORS.secondary.dark,
      color: "white",
    },
  },
  center: { textAlign: "center" },
  flex_2: { flex: 2 },
  normal: { fontWeight: 400 },
};

export const formStyles = {
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 2,
  },
  bodyFlex: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    minHeight: "50vh",

    "@media (max-width: 900px)": {
      flexDirection: "column",
      paddingTop: "20vh",
      minHeight: "auto",
    },
  },
  formContainer: {
    backgroundColor: "#f3f3f3",
    flex: 1,
    minHeight: "50vh",
    paddingBottom: 5,
    paddingLeft: 2,
  },
  header: {
    maxWidth: "500px",
    paddingBottom: 4,
    paddingTop: 20,
    "@media (max-width: 900px)": {
      maxWidth: "100%",
      paddingTop: 5,
    },
  },
  relative: { position: "relative" },
  bgImg: {
    maxWidth: "100%",
    minHeight: "54rem",
    background: `url(${bgForm}) center center no-repeat`,
    backgroundSize: "cover",
    flex: 1,

    "@media (max-width: 900px)": {
      minHeight: "50vh",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  lightText: {
    fontWeight: "light",
    margin: "12px auto",
  },

  line: {
    width: "4.375rem",
    borderTop: "2px solid #A91867",
    marginTop: "-1px",
    marginRight: "10px",
    marginBottom: 0,
    display: "block",
    flexGrow: 0,
  },
  button: {
    padding: ".8em 1.875em",
    borderRadius: "50px",
    backgroundColor: "secondary.main",
    color: "white",

    "&:hover": {
      transform: "scale(1.05)",
      backgroundColor: "secondary.dark",
    },
  },
  formField: {
    backgroundColor: "#fff",
    borderRadius: "30px",
    marginBottom: "20px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "30px",
      },
      "&:hover fieldset": {
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "30px",
      },
    },
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
};

export const footerStyles = {
  container: {
    marginTop: 5,
  },
  bottomBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    width: "2rem",
    borderTop: "2px solid #A91867",
    marginTop: "-1px",
    marginRight: "10px",
    marginBottom: 0,
    display: "block",
    flexGrow: 0,
  },
  toTop: { my: 2, display: "flex", color: "primary.main" },
  listContainer: {
    flexGrow: 1,
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
    alignItems: "center",
  },
  footerList: {
    my: 2,
    display: "flex",
    justifyContent: "center",
    color: "black",
  },
  lightText: {
    fontWeight: 300,
    marginBottom: 1,
    fontSize: "1.5rem",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  details: {
    marginBottom: ".5rem",
  },
  contact: {
    display: "flex",
    alignItems: "center",
    fontSize: ".9rem",
  },
  socials: {
    color: "secondary.main",
    marginRight: ".5rem",

    "&:hover": {
      color: "#000",
    },
  },
  icons: {
    //color: 'secondary.main',
    color: "primary.main",
    fontSize: ".9rem",
    marginRight: ".5rem",

    "&:hover": {
      color: "#000",
    },
  },
  responsive: {
    "@media (max-width: 900px)": {
      flexDirection: "column",
    },
  },
  lineHeight: {
    lineHeight: 2,
  },
};
