import {
  Box,
  Button,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  Container,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { footerStyles } from "../../assets/styles/home";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";

const pages = ["Products", "Pricing", "Admin"];

const FooterLte = () => {
  const langRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [openLangPopover, setOpenLangPopover] = useState<boolean>(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box
      component="footer"
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "background.paper",
        borderTop: "1px solid",
        borderColor: "divider",
        ...footerStyles.container,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={footerStyles.bottomBar}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Copyright{" "}
              <MuiLink
                href="https://myessl.com"
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                ESSL
              </MuiLink>{" "}
              {new Date().getFullYear()}. {t("footer.rights")}
            </Typography>
          </Box>

          <Box sx={footerStyles.listContainer}>
            {pages.map((page) => (
              <Button
                key={page}
                component={Link}
                to={`/${page.toLowerCase()}/login`}
                sx={footerStyles.footerList}
              >
                {t(`footer.pages.${page.toLowerCase()}`)}
              </Button>
            ))}

            <Button
              ref={langRef}
              onClick={() => setOpenLangPopover(true)}
              sx={footerStyles.footerList}
            >
              {i18n.language === "en" ? "English(en)" : "Español(es)"}
            </Button>

            <Popover
              anchorEl={langRef.current}
              open={openLangPopover}
              onClose={() => setOpenLangPopover(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              PaperProps={{
                sx: { minWidth: "150px" },
              }}
            >
              <MenuList>
                <MenuItem
                  onClick={() => {
                    changeLanguage("en");
                    setOpenLangPopover(false);
                  }}
                >
                  English {"(en)"}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeLanguage("es");
                    setOpenLangPopover(false);
                  }}
                >
                  Spanish {"(es)"}
                </MenuItem>
              </MenuList>
            </Popover>

            <Button
              onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
              sx={footerStyles.toTop}
            >
              <KeyboardArrowUpIcon
                fontSize="small"
                sx={{ mr: 0.5, color: "secondary.main" }}
              />
              {t("footer.toTop")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterLte;
