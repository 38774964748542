import React, { useState } from 'react';
import {
  Box,
  TextField,
  Alert,
  Button,
  LinearProgress,
  AlertColor,
  Typography,
} from '@mui/material';
import { commonStyles, quoteStyles } from '../../assets/styles/home';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { getPayment, handlePay } from '../../store/api';

interface PaymentData {
  cost: string;
  subject: string;
  firstname: string;
  lastname: string;
}

const CodePayment = () => {
  const { t } = useTranslation();

  const [al, showAlert] = useState<{
    type: AlertColor;
    msg: string;
  } | null>(null);
  const [token, setTk] = useState<string>('');

  const toStripe = async (data: PaymentData) => {
    if (data?.cost) {
      await handlePay({
        amt: parseInt(data.cost) * 100,
        description: data.subject,
        name: data.firstname + ' ' + data.lastname,
      });
    }
  };

  const { mutate, isLoading } = useMutation(getPayment, {
    onSuccess: (data) => {
      if (data.status && data.status !== 200) {
        throw new Error(
          'Failed to retrieve payment details, check code and try again'
        );
      }
      showAlert({
        type: 'success',
        msg: 'Details of payment received, proceeding to payment shortly...',
      });
      toStripe(data.detail);
      return;
    },
    onError: (err) => {
      showAlert({
        type: 'error',
        msg: 'Failed to retrieve payment details, check code and try again',
      });
      console.log(err);
    },
  });

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (token.length < 1) {
      showAlert({
        type: 'info',
        msg: 'stripe.info',
      });
      return;
    }

    mutate(parseInt(token));
  };
  return (
    <Box>
      <Box sx={{ m: 1 }}>
        <Typography variant="subtitle1" color={'primary.dark'}>
          {t('stripe.title')}
        </Typography>
        <Typography
          color={'primary.main'}
          variant="subtitle2"
          sx={{
            my: 1,
          }}
        >
          {t('stripe.msg')}
        </Typography>
      </Box>
      {isLoading && <LinearProgress />}
      {al && (
        <Alert severity={al?.type} onClose={() => showAlert(null)}>
          {t(al?.msg)}
        </Alert>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={commonStyles.formMargin}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          id="token"
          label={t('stripe.title')}
          variant="outlined"
          value={token}
          onChange={(e) => setTk(e.target.value)}
        />
        <Box sx={quoteStyles.buttons}>
          <Button type="submit" sx={{ marginBottom: 2 }} endIcon={<SendIcon />}>
            {t('forms.common.submit')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CodePayment;
