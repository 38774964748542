export type tCrumb = {
  icon: string;
  menu: string;
};
export type tContact = {
  firstname?: string;
  lastname?: string;
  email: string;
  phone?: string;
  message?: string;
};
type BaseUser = {
  id?: string;
  firstname: string;
  lastname: string;
  email: string;
  roles?: string[];
};
export type UserResponse = BaseUser & {
  updatedOn?: Date;
  token: string;
  status: boolean;
};

export type tUser = BaseUser & {
  auth?: boolean;
  country: string;
  phone?: string;
  token?: string;
  dp?: string;
  password?: string;
  age?: string | number;
  status?: string;
  active?: boolean;
  createdOn?: string;
  modifiedOn?: string;
  lastLogin?: string;
  role?: string;
  bio?: Bio;
  meta?: Bio;
};
export interface SignupFormData extends tUser {
  cpassword: string;
}
type Status = 'STOPPED' | 'DONE' | 'INACTIVE' | 'DELETED';

export type UserDto = {
  email: string;
  usr?: number;
  status?: Status;
  age: number;
  firstname: string;
  password?: string;
  lastname?: string;
  dp?: string;
  token?: string;
  country?: string;
  phone?: string;
  message?: string;
  meta?: Record<string, unknown>;
};

export type tDelete = {
  id: string;
  title: string;
};
export type tLogin = { email: string; password: string };
export type tBio = { id: string; more: string };
export interface Bio {
  dob?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  ssn?: string;
  ein?: string;
  company_name?: string;
  taxyear?: string;
  admin?: boolean;
  bio?: string;
}
export type tCard = {
  amt: number;
  currency?: string;
  name?: string;
  description: string | undefined;
  purpose?: string;
  email?: string;
  phone?: string;
  address?: string;
  amount?: number;
  firstname?: string;
  lastname?: string;
};
export type tBlog = {
  id?: number;
  _id?: string;
  title: string;
  image: string;
  thumb: string;
  category: string;
  brief: string;
  details: string;
  updatedAt: string;
  author?: {
    image: string;
    name: string;
    bio: string;
  };
  socials: {
    linkedin: string;
    github: string;
    twitter: string;
  };
};

export enum AppEnum {
  aefinance = 'aefinance',
  essl = 'essl',
  harmonypflege = 'harmonypflege',
}
export type tBlogInput = {
  id?: string | number;
  title: string;
  image?: string;
  category: string;
  brief: string;
  details: string;
  app: AppEnum;
  tags: [string];
  author: string;
  bio?: string;
  userImage?: string;
  updatedAt?: string;
};
export type tInsurance = {
  id?: string;
  duration?: string;
  plan?: string;
  payeeEmail: string;
  payeeId: string;
  createdOn?: Date;
  updatedOn?: Date;
  detail?: Record<string, unknown>;
};
type BaseDoc = {
  title: string;
  service: string;
  filepaths: string[];
  meta?: Record<string, unknown>;
};

export type DocResponse = BaseDoc & {
  id?: string;
  ownerId?: string;
  comment?: string;
  createdOn?: Date;
};

export type DocDto = BaseDoc & {
  userId: string;
};
