import { Alert } from "@mui/material";
import { useState } from "react";

export interface AlertState {
  open: boolean;
  type: "success" | "error" | "warning";
  message: string;
}
const useAlert = () => {
  const [alert, setAlert] = useState<AlertState>({
    open: false,
    type: "success",
    message: "",
  });

  const alertDisplay = () => {
    return (
      alert.open && (
        <Alert
          severity={alert.type}
          onClose={() => setAlert({ ...alert, open: false })}
          sx={{ mb: 2 }}
        >
          {alert.message}
        </Alert>
      )
    );
  };

  return { alert, alertDisplay, setAlert };
};
export default useAlert;
