import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
  Container,
} from '@mui/material';
import { Search, Refresh, FileDownload } from '@mui/icons-material';
import { format } from 'date-fns';
import { useQuery, useQueryClient } from 'react-query';
import { getNewsletter } from '../../store/api';
import Portal from './Portal';

interface Subscriber {
  id: string;
  email: string;
  createdOn: string;
  modifiedOn: string;
  detail: string | null;
}

type Order = 'asc' | 'desc';
type OrderBy = keyof Pick<Subscriber, 'email' | 'createdOn' | 'id'>;

interface TableHeadProps {
  order: Order;
  orderBy: OrderBy;
  onRequestSort: (property: OrderBy) => void;
}

const descendingComparator = <T, K extends keyof T>(
  a: T,
  b: T,
  orderBy: K
): number => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (
  order: Order,
  orderBy: OrderBy
): ((a: Subscriber, b: Subscriber) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const downloadCSV = (data: Subscriber[]) => {
  const headers = ['Email', 'Subscribed On', 'Id'];

  const csvData = data.map((row) => [
    row.email,
    format(new Date(row.createdOn), 'PPP'),
    row.id,
  ]);

  const csvContent = [headers, ...csvData]
    .map((row) => row.map((cell) => `"${cell}"`).join(','))
    .join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute(
    'download',
    `newsletter_subscribers_${format(new Date(), 'yyyy-MM-dd')}.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const EnhancedTableHead: React.FC<TableHeadProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const headers: Array<{
    id: OrderBy;
    label: string;
  }> = [
    { id: 'email', label: 'Email' },
    { id: 'createdOn', label: 'Subscribed On' },
    { id: 'id', label: 'Id' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header.id}>
            <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={() => onRequestSort(header.id)}
            >
              {header.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const NewsletterSubscribers: React.FC = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<OrderBy>('createdOn');
  const [order, setOrder] = useState<Order>('desc');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, error, isLoading } = useQuery('subscribers', getNewsletter);

  const filteredData = useMemo(() => {
    return (
      data?.filter((subscriber: Subscriber) =>
        subscriber.email.toLowerCase().includes(searchTerm.toLowerCase())
      ) || []
    );
  }, [data, searchTerm]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort(getComparator(order, orderBy));
  }, [filteredData, order, orderBy]);

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        Error loading subscribers data
      </Alert>
    );
  }

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: OrderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries('subscribers');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  const handleDownloadCSV = () => {
    if (filteredData.length > 0) {
      downloadCSV(filteredData);
    }
  };

  return (
    <Portal>
      <Container>
        <Box sx={{ width: '100%', p: 3 }}>
          <Box
            sx={{
              mb: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" component="h1">
              Newsletter Subscribers
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Tooltip title="Download as CSV">
                <span>
                  <Button
                    variant="outlined"
                    startIcon={<FileDownload />}
                    onClick={handleDownloadCSV}
                    disabled={!data || filteredData.length === 0}
                  >
                    Export CSV
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Refresh data">
                <IconButton onClick={handleRefresh} color="primary">
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by email..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!data || isLoading ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : paginatedData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No subscribers found
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedData.map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          {format(new Date(row.createdOn), 'PPP')}
                        </TableCell>
                        <TableCell>{row.id}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Container>
    </Portal>
  );
};

export default NewsletterSubscribers;
