import Portal from "./Portal";

const Settings = () => {
  return (
    <Portal>
      <div>
        <h1>Setting</h1>
      </div>
    </Portal>
  );
};

export default Settings;
