import { List, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { Appcontext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import {
  AddComment,
  MiscellaneousServices,
  Newspaper,
} from '@mui/icons-material';

const Sidebar = () => {
  const { user } = useContext(Appcontext);
  const { t } = useTranslation();
  const isAdmin = user?.roles?.includes('ADMIN');
  const location = useLocation();

  const navItems = [
    {
      title: t('portal.sidebar.home'),
      path: '/',
      icon: HomeIcon,
      allowedRoles: ['ALL'],
    },
    {
      title: t('portal.sidebar.profile'),
      path: '/profile',
      icon: PersonIcon,
      allowedRoles: ['ALL'],
    },
    {
      title: t('portal.sidebar.users'),
      path: '/users',
      icon: PeopleIcon,
      allowedRoles: ['ADMIN'],
    },
    {
      title: 'Subscribers',
      path: '/subscribers',
      icon: Newspaper,
      allowedRoles: ['ADMIN'],
    },
    {
      title: t('portal.sidebar.documents'),
      path: '/admin',
      icon: FolderIcon,
      allowedRoles: ['ALL'],
    },
    {
      title: t('portal.sidebar.settings'),
      path: '/settings',
      icon: SettingsIcon,
      allowedRoles: ['ADMIN'],
    },
    {
      title: t('portal.sidebar.blogs'),
      path: '/Blogs',
      icon: LibraryBooksIcon,
      allowedRoles: ['ALL'],
    },
    {
      title: t('portal.sidebar.newblog'),
      path: '/blog/new',
      icon: AddComment,
      allowedRoles: ['ADMIN'],
    },
    {
      title: t('portal.sidebar.insurance'),
      path: '/allquotes',
      icon: PointOfSaleIcon,
      allowedRoles: ['ADMIN'],
    },
    {
      title: t('portal.sidebar.services'),
      path: '/admin/services',
      icon: MiscellaneousServices,
      allowedRoles: ['ADMIN'],
    },
  ];

  return (
    <List
      sx={{
        width: '100%',
        padding: 0,
        '& .MuiListItem-root': {
          display: 'flex',
          justifyContent: 'center',
          padding: '12px 0',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '& .MuiListItemIcon-root': {
              transform: 'scale(1.1)',
            },
          },
        },
        '& .active': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderRight: '3px solid white',
          '& .MuiListItemIcon-root': {
            color: 'white',
          },
        },
      }}
    >
      {navItems.map((item) => {
        const Icon = item.icon;
        if (
          item.allowedRoles.includes('ALL') ||
          (isAdmin && item.allowedRoles.includes('ADMIN'))
        ) {
          return (
            <Tooltip key={item.path} title={item.title} placement="right" arrow>
              <ListItem
                component={Link}
                to={item.path}
                className={location.pathname === item.path ? 'active' : ''}
                sx={{
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    color: 'rgba(255, 255, 255, 0.7)',
                    transition: 'all 0.3s ease',
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.75rem',
                    },
                  }}
                >
                  <Icon />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          );
        }
        return null;
      })}
    </List>
  );
};

export default Sidebar;
