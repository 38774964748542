import React, { createContext, ReactElement, useReducer } from "react";
import { tUser } from "../store/types";
import { Action, LOADING_SET, LOGOUT, PROFILE_SET, USER_ADD } from "./Action";
interface State {
  loading: boolean;
  user: tUser | null;
  users: [tUser] | [];
  logout: () => void;
}
interface Props {
  children: ReactElement;
}

const getuser = () => {
  let us = localStorage.getItem("user");
  if (us !== undefined && us !== "" && us !== null) {
    us = JSON.parse(us);
    return us;
  } else {
    return {};
  }
};
const initialState: State = {
  user: getuser() as tUser,
  loading: false,
  users: [],
  logout: () => {},
};
const Appcontext = createContext(initialState);
const AppDpx = createContext<React.Dispatch<Action>>(() => {});
const AppProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(
    (state: State = initialState, action: Action): State => {
      switch (action.type) {
        case USER_ADD:
          return {
            ...state,
            users: action.data,
          };
        case PROFILE_SET:
          return {
            ...state,
            user: action?.data || null,
          };
        case LOADING_SET:
          return {
            ...state,
            loading: action.data,
          };
        case LOGOUT:
          localStorage.removeItem("user");
          return {
            ...state,
            user: null,
          };
        default:
          return state;
      }
    },
    initialState
  );
  return (
    <Appcontext.Provider value={state}>
      <AppDpx.Provider value={dispatch}>{children}</AppDpx.Provider>
    </Appcontext.Provider>
  );
};
export { AppProvider, Appcontext, AppDpx };
