import {
  Alert,
  AlertColor,
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SendIcon from '@mui/icons-material/Send';
import { commonStyles, loginStyles } from '../assets/styles/home';
import { useContext, useState } from 'react';
import { Appcontext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import Header from '../components/layout/Header';
import { doToken, resetPass } from '../store/api';
import { useTranslation } from 'react-i18next';

const Forgot = () => {
  const { t } = useTranslation();
  const { loading } = useContext(Appcontext);
  const [al, showAlert] = useState<{
    type: AlertColor;
    msg: string;
  } | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [cpass, setCpass] = useState<string>('');
  const [token, setTk] = useState<string>('');
  const [isToken, setToken] = useState<boolean>(false);
  const navigate = useNavigate();
  const sendResetToken = async () => {
    if (email.length > 0) {
      const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
      let obj;
      if (regex.test(email)) {
        obj = {
          email,
          age: 0,
          firstname: '',
          password: '',
        };

        const sentToken = await doToken(obj);
        if (sentToken.error) {
          showAlert({
            type: 'info',
            msg: t('forms.forgot.errormsg'),
          });

          return;
        }
        showAlert({
          type: 'success',
          msg: 'forms.forgot.successmsg',
        });
        setToken(true);
        return;
      }
      showAlert({ type: 'info', msg: 'forms.forgot.erroremail' });

      return;
    }
    showAlert({ type: 'info', msg: 'forms.forgot.needemail' });
  };
  const resetPassword = async () => {
    if (token.length > 0 && password.length > 0 && cpass.length > 0) {
      if (password === cpass) {
        const obj = {
          email,
          age: 0,
          firstname: '',
          password,
          token,
        };
        const response = await resetPass(obj);
        if (response.error || response.data !== 'DONE') {
          showAlert({
            type: 'error',
            msg: 'forms.forgot.invalid',
          });
          return;
        }
        showAlert({
          type: 'success',
          msg: 'forms.forgot.done',
        });

        setCpass('');
        setPassword('');
        setEmail('');
        setTk('');
        setToken(false);
        navigate('/admin/login');
        return;
      }
      showAlert({ type: 'info', msg: 'forms.forgot.match' });
      return;
    }
    showAlert({ type: 'info', msg: 'forms.forgot.mandatory' });
  };
  return (
    <>
      <Header headerType="relative" />
      <Box sx={loginStyles.card}>
        <Box sx={loginStyles.content}>
          <Box sx={loginStyles.title}>
            <Typography variant="h6" color={'primary.dark'}>
              {t('forms.common.forgot')}
            </Typography>
            <LockPersonIcon color="secondary" />
          </Box>
          {loading && <LinearProgress />}
          {al && (
            <Alert severity={al?.type} onClose={() => showAlert(null)}>
              {t(al?.msg)}
            </Alert>
          )}

          <Box
            component="form"
            sx={commonStyles.formMargin}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              id="email"
              label={t('forms.common.email')}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              type={'email'}
            />
            {isToken && (
              <Box sx={commonStyles.formMargin}>
                <TextField
                  fullWidth
                  id="password"
                  label={t('forms.common.password')}
                  variant="outlined"
                  type={'password'}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  fullWidth
                  id="cpassword"
                  label={t('forms.common.cpassword')}
                  variant="outlined"
                  type={'password'}
                  onChange={(e) => setCpass(e.target.value)}
                />
                <TextField
                  fullWidth
                  id="token"
                  label={t('forms.forgot.token')}
                  variant="outlined"
                  onChange={(e) => setTk(e.target.value)}
                />
              </Box>
            )}
          </Box>
          <Box sx={loginStyles.buttons}>
            <Button
              sx={{ marginBottom: 2 }}
              onClick={isToken ? resetPassword : sendResetToken}
              endIcon={<SendIcon />}
              variant="contained"
            >
              {t('forms.forgot.reset')}
            </Button>
            <Box sx={loginStyles.rowButtons}>
              <Button
                sx={{ marginBottom: 2 }}
                onClick={() => navigate('/admin/login')}
              >
                {t('forms.common.login')}
              </Button>
              <Button
                sx={{ marginBottom: 2 }}
                onClick={() => navigate('/newuser')}
              >
                {t('forms.forgot.access')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Forgot;
