import { DeleteForever, Warning } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  documentTitle: string;
}
const DeleteConfirmationDialog: React.FC<DeleteDialogProps> = ({
  open,
  onClose,
  onConfirm,
  documentTitle,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Warning color="error" />
      Confirm Delete
    </DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to delete the document &quot;{documentTitle}&quot;
        and all its associated files? This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        variant="contained"
        color="error"
        onClick={onConfirm}
        startIcon={<DeleteForever />}
      >
        Delete Document
      </Button>
    </DialogActions>
  </Dialog>
);
export default DeleteConfirmationDialog;
