import { Box, Container, Paper, Typography, styled } from '@mui/material';
import '../../assets/styles/float.css';
import adviceIcon from '../../assets/img/advice.webp';
import journalIcon from '../../assets/img/journal.webp';
import withholdingIcon from '../../assets/img/withholding.webp';
import customerCareIcon from '../../assets/img/customer-care.webp';
import cashFlowIcon from '../../assets/img/cash-flow.webp';
import checkListIcon from '../../assets/img/check-list.webp';
import { useTranslation } from 'react-i18next';

const Jumbotron = styled(Paper)<DirectionProps>(({ direction }) => ({
  display: 'flex',
  flexDirection: direction,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2rem',
  borderRadius: '16px',
  boxShadow: 'none',
  background: 'transparent',
  gap: '3rem',

  '@media (max-width: 900px)': {
    flexDirection: 'column',
    padding: '1rem',
  },
}));

const StyledList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: '1.5rem 0',
  '& li': {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    '&::before': {
      content: '"•"',
      color: '#A91867',
      fontWeight: 'bold',
      marginRight: '0.5rem',
      fontSize: '1.2rem',
    },
  },
});

const servicesStyles = {
  container: {
    padding: '4rem 0',
    my: 4,
    transition: 'background-color 0.3s ease',
  },
  body: {
    flex: 1,
    '@media (max-width: 900px)': {
      marginBottom: '3rem',
    },
  },
  img: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      maxWidth: '400px',
      width: '100%',
      height: 'auto',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  },
  title: {
    fontWeight: 500,
    margin: '1rem 0',
    fontSize: '2.5rem',
    color: '#2C3E50',
    position: 'relative',
    marginBottom: '2rem',
  },
  paragraph: {
    margin: '1.5rem 0',
    lineHeight: 1.8,
    color: '#505965',
    fontSize: '1.1rem',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    width: '4rem',
    height: '3px',
    background: 'linear-gradient(90deg, #A91867 0%, #FF5E9E 100%)',
    marginRight: '15px',
    borderRadius: '4px',
    animation: 'width 1s ease',

    '@media (max-width: 900px)': {
      width: '3rem',
    },
  },
};

type DirectionProps = {
  direction: 'row' | 'row-reverse';
};

const ServicesBody = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #F8F9FA 100%)' }}
    >
      {services.map((service, index) => {
        const serviceNumber = `service${index + 1}`;
        const paragraphs = t(`services.body.${serviceNumber}.paragraphs`, {
          returnObjects: true,
        });
        const lists = t(`services.body.${serviceNumber}.lists`, {
          returnObjects: true,
        });
        const listsArray = Array.isArray(lists) ? lists : lists ? [lists] : [];

        return (
          <Box
            key={index}
            sx={[
              servicesStyles.container,
              {
                backgroundColor:
                  index % 2 === 0 ? 'transparent' : 'rgba(240, 247, 255, 0.5)',
                '&:hover': {
                  backgroundColor:
                    index % 2 === 0
                      ? 'rgba(240, 247, 255, 0.2)'
                      : 'rgba(240, 247, 255, 0.7)',
                },
              },
            ]}
          >
            <Container maxWidth="lg">
              <Jumbotron direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
                <Box component="div" sx={servicesStyles.body}>
                  <Typography
                    variant="h3"
                    sx={[servicesStyles.title, servicesStyles.flex]}
                  >
                    <Box component="div" sx={servicesStyles.line} />
                    {t(service.title)}
                  </Typography>
                  <Box sx={{ opacity: 0.9 }}>
                    {Array.isArray(paragraphs) &&
                      paragraphs.map((paragraph: string, pIndex: number) => (
                        <Typography
                          key={pIndex}
                          variant="subtitle1"
                          sx={servicesStyles.paragraph}
                        >
                          {paragraph}
                        </Typography>
                      ))}
                  </Box>
                  {listsArray.length > 0 && (
                    <StyledList>
                      {listsArray.map((list: string, lIndex: number) => (
                        <li key={lIndex}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: '#505965',
                              fontSize: '1.05rem',
                            }}
                          >
                            {list}
                          </Typography>
                        </li>
                      ))}
                    </StyledList>
                  )}
                </Box>
                <Box
                  component="div"
                  sx={servicesStyles.img}
                  className="service-image-container"
                >
                  <img
                    src={service.icon}
                    alt="service-description"
                    className="float-img"
                  />
                </Box>
              </Jumbotron>
            </Container>
          </Box>
        );
      })}
    </Box>
  );
};

export default ServicesBody;
const services = [
  {
    title: 'services.body.service1.title',
    paragraphs: 'services.body.service1.paragraphs',
    lists: 'services.body.service1.lists',
    icon: adviceIcon,
  },
  {
    title: 'services.body.service2.title',
    paragraphs: 'services.body.service2.paragraphs',
    lists: 'services.body.service2.lists',
    icon: journalIcon,
  },
  {
    title: 'services.body.service3.title',
    paragraphs: 'services.body.service3.paragraphs',
    lists: 'services.body.service3.lists',
    icon: withholdingIcon,
  },
  {
    title: 'services.body.service4.title',
    paragraphs: 'services.body.service4.paragraphs',
    lists: 'services.body.service4.lists',
    icon: customerCareIcon,
  },
  {
    title: 'services.body.service5.title',
    paragraphs: 'services.body.service5.paragraphs',
    lists: 'services.body.service5.lists',
    icon: cashFlowIcon,
  },
  {
    title: 'services.body.service6.title',
    paragraphs: 'services.body.service6.paragraphs',
    lists: 'services.body.service6.lists',
    icon: checkListIcon,
  },
];
