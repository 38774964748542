export const essl = 'https://everlasting.work/api/v1/';
export const horaceapi = 'https://horacelearning.us/';
export const s3path = 'https://esslcars.s3.eu-west-1.amazonaws.com/';
export const lambda =
  'https://xtz05mugj7.execute-api.eu-west-1.amazonaws.com/dev/';
const authKey = process.env.REACT_APP_auth;

export const putOptions = (fd: FormData) => {
  // move this to api.ts
  return {
    method: 'PUT',
    body: fd,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Basic ${authKey}`,
    },
  };
};
export const geo = {
  lat: parseInt('29.758938'),
  lng: parseInt('-95.367697'),
};
export const path =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_LOCAL_PATH
    : process.env.REACT_APP_PROD_PATH;
