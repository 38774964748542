import React from 'react';
import {
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { newsLetterStyles } from '../../assets/styles/home';
import { addNewsletter } from '../../store/api';
type Severity = 'error' | 'warning' | 'info' | 'success';
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const Newsletter: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState<string>('');
  const [al, showAlert] = React.useState({
    show: false,
    message: '',
    severity: 'success' as Severity,
  });

  const joinlist = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!EMAIL_REGEX.test(email)) {
      showAlert({
        show: true,
        message: t('newsletter.invalid'),
        severity: 'error',
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await addNewsletter({ email });
      if (response.error) {
        showAlert({
          show: true,
          message: t('newsletter.error'),
          severity: 'error',
        });
      } else {
        showAlert({
          show: true,
          message: t('newsletter.alert'),
          severity: 'success',
        });
        setEmail('');
      }
    } catch {
      showAlert({
        show: true,
        message: t('newsletter.error'),
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box sx={newsLetterStyles.container}>
      <Container maxWidth="lg" sx={newsLetterStyles.main}>
        <Box component="div" sx={newsLetterStyles.flex_2}>
          <Typography variant="h4" sx={newsLetterStyles.normal}>
            {t('newsletter.title')}
          </Typography>
          <Typography variant="h6" my={2} sx={newsLetterStyles.normal}>
            {t('newsletter.subtitle')}
          </Typography>
        </Box>
        {isLoading && <CircularProgress />}
        <Box
          component="form"
          sx={newsLetterStyles.form}
          noValidate
          autoComplete="off"
          onSubmit={(e) => joinlist(e)}
        >
          {al.show && (
            <Alert
              sx={newsLetterStyles.center}
              severity={al.severity}
              onClose={() =>
                showAlert({ show: false, message: '', severity: 'success' })
              }
            >
              {al.message}
            </Alert>
          )}
          <TextField
            sx={newsLetterStyles.textField}
            id="email"
            placeholder={t('newsletter.placeholder') || 'Email'}
            variant="outlined"
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            sx={newsLetterStyles.button}
            id="aef-newsletter"
          >
            {t('newsletter.button')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Newsletter;
