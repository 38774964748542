import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Chip,
  Tooltip,
  Divider,
  Button,
  CardActions,
} from '@mui/material';
import {
  DeleteForever,
  Download as DownloadIcon,
  Description,
  Folder,
} from '@mui/icons-material';
import { DocumentMetadata } from '../documents/AddDoc';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useAlert from '../../hooks/useAlert';
import DeleteConfirmationDialog from '../../components/services/DeleteConfirmationDialog';

const s3path = 'https://aefinancials.s3.amazonaws.com/';

const styles = {
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    },
  },
  cardContent: {
    flexGrow: 1,
    padding: '20px',
  },
  cardActions: {
    padding: '16px',
    background: 'rgba(0,0,0,0.02)',
    borderTop: '1px solid rgba(0,0,0,0.05)',
    justifyContent: 'flex-end',
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '8px',
    background: 'rgba(0,0,0,0.02)',
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  fileActions: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '4px',
  },
  deleteButton: {
    color: 'error.main',
    borderColor: 'error.main',
    '&:hover': {
      backgroundColor: 'error.main',
      color: 'white',
    },
  },
};
interface DocumentsGridProps {
  documents: DocumentMetadata[];
  _delete: (path: string, id: string) => void;
  delDocument: (id: string) => void;
}

const DocumentsGrid: React.FC<DocumentsGridProps> = ({
  documents,
  _delete,
  delDocument,
}) => {
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    docId: string | null;
    title: string;
  }>({
    open: false,
    docId: null,
    title: '',
  });
  const { t } = useTranslation();
  const { setAlert, alertDisplay } = useAlert();

  const handleDeleteDocument = (doc: DocumentMetadata) => {
    setDeleteDialog({
      open: true,
      docId: doc.id ?? null,
      title: doc.title,
    });
  };

  const confirmDeleteEntireDoc = async () => {
    if (deleteDialog.docId) {
      try {
        delDocument(deleteDialog.docId);
        setAlert({
          message: 'Document deleted successfully',
          type: 'success',
          open: true,
        });
      } catch (error) {
        setAlert({
          message: 'An error occurred while deleting the document',
          type: 'error',
          open: true,
        });
        throw new Error(String(error));
      } finally {
        setDeleteDialog({ open: false, docId: null, title: '' });
      }
    }
  };
  if (documents.length === 0) {
    return (
      <Typography variant="h6" color="textSecondary" align="center">
        No documents found
      </Typography>
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        {documents?.map((doc: DocumentMetadata) => (
          <Grid item xs={12} sm={6} md={4} key={doc.id}>
            {alertDisplay()}
            <Card sx={styles.card}>
              <CardContent sx={styles.cardContent}>
                {/* Service Badge */}
                <Box mb={2}>
                  <Chip
                    icon={<Folder fontSize="small" />}
                    label={doc?.service}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                </Box>

                {/* Document Title */}
                <Typography variant="h6" gutterBottom>
                  {doc?.title}
                </Typography>

                {/* Metadata */}
                <Box mt={2} mb={3}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="div"
                    sx={{ mb: 1 }}
                  >
                    {t('portal.docs.created')}:{' '}
                    {doc?.createdOn && (
                      <Box component="span" fontWeight="medium">
                        {new Date(doc?.createdOn).toLocaleDateString()}
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="div"
                  >
                    {t('portal.docs.uploaded')}:{' '}
                    {doc?.meta?.uploadedBy && (
                      <Box component="span" fontWeight="medium">
                        {doc?.meta?.uploadedBy}
                      </Box>
                    )}
                  </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Files List */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="text.secondary"
                  >
                    Attached Files
                  </Typography>
                  {doc.filepaths.map((path) => (
                    <Box key={path} sx={styles.fileContainer}>
                      <Description
                        fontSize="small"
                        color="action"
                        sx={{ mr: 1.5 }}
                      />
                      <Typography variant="body2" noWrap sx={{ flex: 1 }}>
                        {path.split('/').pop()}
                      </Typography>
                      <Box sx={styles.fileActions}>
                        {doc?.filepaths.length > 1 && (
                          <Tooltip title="Delete file">
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => _delete(path, doc?.id ?? '')}
                              sx={{
                                '&:hover': {
                                  background: 'rgba(211, 47, 47, 0.04)',
                                },
                              }}
                            >
                              <DeleteForever fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Download">
                          <IconButton
                            size="small"
                            color="primary"
                            component="a"
                            href={`${s3path}${path}`}
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                              '&:hover': {
                                background: 'rgba(25, 118, 210, 0.04)',
                              },
                            }}
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CardContent>
              <CardActions sx={styles.cardActions}>
                <Button
                  variant="outlined"
                  startIcon={<DeleteForever />}
                  sx={styles.deleteButton}
                  onClick={() => handleDeleteDocument(doc)}
                >
                  Delete Document
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <DeleteConfirmationDialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, docId: null, title: '' })}
        onConfirm={confirmDeleteEntireDoc}
        documentTitle={deleteDialog.title}
      />
    </>
  );
};

export default DocumentsGrid;
