import { Box, LinearProgress, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Portal from './Portal';
import { aefClients } from '../../store/api';
import { AddCircle, Person, Shield } from '@mui/icons-material';
import { tUser } from '../../store/types';

const RoleIcon = ({ role }: { role: string }) => {
  return (
    <Box>
      <Tooltip title={role[1]} placement="top">
        {role?.includes('ADMIN') ? (
          <Shield className="w-5 h-5 text-blue-600" />
        ) : (
          <Person className="w-5 h-5 text-gray-600" />
        )}
      </Tooltip>
    </Box>
  );
};

const useUserColumns = () => {
  const { t } = useTranslation();

  return [
    { field: 'id', headerName: 'ID', width: 130 },
    {
      field: 'roles',
      headerName: 'Role',
      width: 70,
      renderCell: (params) => <RoleIcon role={params.row.roles} />,
      sortable: false,
    },
    {
      field: 'full_name',
      headerName: 'Fullname',
      width: 260,
      valueGetter: (params) => `${params.row.firstname} ${params.row.lastname}`,
      flex: 1,
    },
    {
      field: 'email',
      headerName: t('forms.common.email'),
      width: 130,
      flex: 1.5,
    },
    {
      field: 'phone',
      headerName: t('forms.common.phone'),
      width: 130,
      valueGetter: (params) => params.row?.bio?.phone,
      flex: 1,
    },
    {
      field: 'ssn',
      headerName: t('forms.common.ssn'),
      width: 60,
      valueGetter: (params) => params.row?.bio?.ssn,
      flex: 1,
    },
    {
      field: 'ein',
      headerName: t('forms.common.ein'),
      width: 60,
      valueGetter: (params) => params.row?.bio?.ein,
      flex: 1,
    },
    {
      field: 'company_name',
      headerName: t('forms.common.company_name'),
      width: 130,
      valueGetter: (params) => params.row?.bio?.company_name,
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 130,
      valueGetter: (params) => {
        const { street, city, state, country } = params.row?.bio || {};
        return `${street || ''}, ${city || ''}, ${state || ''}, ${country || ''}`
          .trim()
          .replace(/^,|,$/g, '');
      },
      flex: 1,
    },
    {
      field: 'createdOn',
      headerName: t('portal.docs.created'),
      width: 130,
      valueGetter: (params) =>
        new Date(params.row.createdOn).toLocaleDateString(),
      flex: 1,
    },
  ] as GridColDef[];
};

const UsersGrid = ({
  data,
  onUserSelect,
}: {
  data: tUser[];
  onUserSelect: (user: tUser) => void;
}) => {
  const columns = useUserColumns();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 750 }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        onSelectionModelChange={(ids) => {
          console.log(ids);
          const selectedUser = data.find((user) => user.id === ids[0]);
          if (selectedUser) onUserSelect(selectedUser);
        }}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
};

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery('users', aefClients);

  const handleUserSelect = (user: tUser) => {
    navigate('/addclient', { state: { user } });
  };

  return (
    <Portal>
      <div className="p-4">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl tracking-wide">
            {t('portal.sidebar.users')} ({data?.length ?? 0})
          </h1>
          <Link
            to="/addclient"
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
          >
            <AddCircle className="w-6 h-6" />
          </Link>
        </div>

        {isLoading && <LinearProgress />}

        {data?.length > 0 && (
          <UsersGrid data={data} onUserSelect={handleUserSelect} />
        )}
      </div>
    </Portal>
  );
};

export default Users;
