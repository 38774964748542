import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Typography,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Chip,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Delete, Add, Edit, Save, Cancel } from "@mui/icons-material";
import Portal from "./Portal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addService, getAllServices } from "../../store/api";

export interface Service {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  createdOn: string;
  modifiedOn: string;
  offerings: string[];
  checklist: string[];
}

interface EditDialogProps {
  open: boolean;
  service: Service | null;
  onClose: () => void;
  onSave: (updatedService: Service) => void;
}

interface EditableListItemProps {
  value: string;
  onSave: (newValue: string) => void;
  onDelete: () => void;
}

const EditableListItem: React.FC<EditableListItemProps> = ({
  value,
  onSave,
  onDelete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleSave = () => {
    if (editValue.trim() !== "") {
      onSave(editValue.trim());
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(value);
    setIsEditing(false);
  };

  return (
    <ListItem divider>
      {isEditing ? (
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <TextField
            fullWidth
            size="small"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSave()}
            autoFocus
          />
          <IconButton onClick={handleSave} color="primary" size="small">
            <Save />
          </IconButton>
          <IconButton onClick={handleCancel} color="error" size="small">
            <Cancel />
          </IconButton>
        </Box>
      ) : (
        <>
          <ListItemText primary={value} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => setIsEditing(true)}
              color="primary"
            >
              <Edit />
            </IconButton>
            <IconButton edge="end" onClick={onDelete} color="error">
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </>
      )}
    </ListItem>
  );
};

const EditServiceDialog: React.FC<EditDialogProps> = ({
  open,
  service,
  onClose,
  onSave,
}) => {
  const [editedService, setEditedService] = useState<Service | null>(null);
  const [newOffering, setNewOffering] = useState("");

  useEffect(() => {
    setEditedService(service);
  }, [service]);

  if (!editedService) return null;

  const handleChange =
    (field: keyof Service) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedService({
        ...editedService,
        [field]: e.target.value,
      });
    };

  const handleAddOffering = () => {
    if (newOffering.trim()) {
      setEditedService({
        ...editedService,
        offerings: [...editedService.offerings, newOffering.trim()],
      });
      setNewOffering("");
    }
  };

  const handleRemoveOffering = (index: number) => {
    setEditedService({
      ...editedService,
      offerings: editedService.offerings.filter((_, i) => i !== index),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Service Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Title"
              value={editedService.title}
              onChange={handleChange("title")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Description"
              value={editedService.description}
              onChange={handleChange("description")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="date"
              label="Start Date"
              value={editedService.startDate}
              onChange={handleChange("startDate")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="date"
              label="End Date"
              value={editedService.endDate}
              onChange={handleChange("endDate")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Offerings
            </Typography>
            <Box display="flex" gap={2} mb={2}>
              <TextField
                fullWidth
                size="small"
                value={newOffering}
                onChange={(e) => setNewOffering(e.target.value)}
                placeholder="Add new offering"
              />
              <Button variant="outlined" onClick={handleAddOffering}>
                Add
              </Button>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {editedService.offerings.map((offering, index) => (
                <Chip
                  key={index}
                  label={offering}
                  onDelete={() => handleRemoveOffering(index)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={() => onSave(editedService)}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const ServicesPage: React.FC = () => {
  const [newDocument, setNewDocument] = useState<string>("");
  const [activeServiceId, setActiveServiceId] = useState<string>("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const queryClient = useQueryClient();
  const { data: services = [], isLoading } = useQuery<Service[]>(
    "services",
    getAllServices
  );

  const updateServiceMutation = useMutation(addService, {
    onSuccess: () => {
      queryClient.invalidateQueries("services");
    },
  });

  const activeService = services.find((s) => s.id === activeServiceId);

  useEffect(() => {
    if (services.length > 0 && !activeServiceId) {
      setActiveServiceId(services[0].id);
    }
  }, [services, activeServiceId]);

  const handleAddChecklist = (): void => {
    if (!activeService || !newDocument.trim()) return;

    const updatedService = {
      ...activeService,
      checklist: [...activeService.checklist, newDocument.trim()],
    };

    updateServiceMutation.mutate(updatedService);
    setNewDocument("");
  };

  const handleRemoveChecklist = (index: number): void => {
    if (!activeService) return;

    const updatedService = {
      ...activeService,
      checklist: activeService.checklist.filter((_, i) => i !== index),
    };

    updateServiceMutation.mutate(updatedService);
  };
  const handleEditChecklist = (index: number, newValue: string): void => {
    if (!activeService) return;

    const updatedChecklist = [...activeService.checklist];
    updatedChecklist[index] = newValue;

    const updatedService = {
      ...activeService,
      checklist: updatedChecklist,
    };

    updateServiceMutation.mutate(updatedService);
  };

  const handleSaveService = (updatedService: Service) => {
    updateServiceMutation.mutate(updatedService);
    setEditDialogOpen(false);
  };

  return (
    <Portal>
      <Container maxWidth="md">
        <Card>
          <CardHeader
            title="Services Documents Checklist"
            action={
              activeService && (
                <IconButton onClick={() => setEditDialogOpen(true)}>
                  <Edit />
                </IconButton>
              )
            }
          />
          <CardContent>
            {isLoading && <LinearProgress />}
            <Box display="flex" flexWrap="wrap" gap={2} mb={4}>
              {services.map((service) => (
                <Button
                  key={service.id}
                  variant={
                    service.id === activeServiceId ? "contained" : "outlined"
                  }
                  onClick={() => setActiveServiceId(service.id)}
                >
                  {service.title}
                </Button>
              ))}
            </Box>

            {activeService && (
              <>
                <Box mt={4}>
                  <Typography variant="h6">{activeService.title}</Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    {activeService.description}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={2} mt={2}>
                    <TextField
                      fullWidth
                      value={newDocument}
                      onChange={(e) => setNewDocument(e.target.value)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && handleAddChecklist()
                      }
                      placeholder="Add new checklist item"
                      variant="outlined"
                      size="small"
                    />
                    <Button
                      variant="contained"
                      onClick={handleAddChecklist}
                      endIcon={<Add />}
                      disabled={!newDocument.trim()}
                    >
                      Add Item
                    </Button>
                  </Box>
                </Box>

                <Box mt={4}>
                  {activeService.checklist.length > 0 ? (
                    <List>
                      {activeService.checklist.map((doc, index) => (
                        <EditableListItem
                          key={`${doc}-${index}`}
                          value={doc}
                          onSave={(newValue) =>
                            handleEditChecklist(index, newValue)
                          }
                          onDelete={() => handleRemoveChecklist(index)}
                        />
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No checklist items available
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </CardContent>
        </Card>

        <EditServiceDialog
          open={editDialogOpen}
          service={activeService || null}
          onClose={() => setEditDialogOpen(false)}
          onSave={handleSaveService}
        />
      </Container>
    </Portal>
  );
};

export default ServicesPage;
