import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Divider,
  useTheme,
} from '@mui/material';
import OfferCard from './OfferCard';
import { aefinance } from '../../store/aefinance';
import Map from './Map';
import { useTranslation } from 'react-i18next';
import locationImage from '../../assets/img/location.webp';
import internationalBusinessImage from '../../assets/img/international-business.webp';
import emailImage from '../../assets/img/email.webp';
import phoneCallImage from '../../assets/img/phone-call.webp';

const CONTACT_CARDS = [
  {
    id: 1,
    title: 'offices.second.contact1',
    image: locationImage,
    details: aefinance.contact.address,
  },
  {
    id: 2,
    title: 'offices.second.contact2',
    image: internationalBusinessImage,
    details: aefinance.contact.address2,
  },
  {
    id: 3,
    title: 'offices.second.contact3',
    image: emailImage,
    email: aefinance.contact.email,
    details: aefinance.contact.emailText,
  },
  {
    id: 4,
    title: 'offices.second.contact4',
    image: phoneCallImage,
    details: aefinance.contact.phone,
  },
];

const Offices = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: theme.palette.background.default,
    },
    container: {
      py: 6,
    },
    header: {
      maxWidth: '500px',
      mb: 5,
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: 2,
    },
    line: {
      width: '70px',
      height: '2px',
      backgroundColor: theme.palette.secondary.main,
      flexShrink: 0,
    },
    subtitle: {
      fontWeight: 300,
      color: theme.palette.text.primary,
      my: 2,
    },
    intro: {
      color: theme.palette.text.secondary,
      mb: 3,
    },
    cardsContainer: {
      mt: 6,
      mb: 8,
    },
  };
  return (
    <Box sx={styles.root}>
      <Container maxWidth="lg" sx={styles.container}>
        {/* Header Section */}
        <Box sx={styles.header}>
          <Box sx={styles.titleWrapper}>
            <Box component="span" sx={styles.line} />
            <Typography variant="h6" color="secondary">
              {t('offices.first.title')}
            </Typography>
          </Box>

          <Typography variant="h3" sx={styles.subtitle}>
            {t('offices.first.subtitle')}
          </Typography>

          <Typography variant="subtitle1" sx={styles.intro}>
            {t('offices.first.intro')}
          </Typography>
        </Box>

        <Divider />

        {/* Contact Cards Grid */}
        <Box sx={styles.cardsContainer}>
          <Grid container spacing={3}>
            {CONTACT_CARDS.map((card) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <OfferCard items={card} />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Map Component */}
        <Map />
      </Container>
    </Box>
  );
};

export default Offices;
