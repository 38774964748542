import React from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  styled,
  Popover,
} from '@mui/material';
import { heroStyles as styles } from '../../assets/styles/home';
import { useNavigate } from 'react-router-dom';
import backgroundTriangle from '../../assets/img/bgn-triangle-bottom.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import { useTranslation } from 'react-i18next';
import webBg1 from '../../assets/img/web-bg1-min.webp';
import webBg2 from '../../assets/img/web-bg2-min.webp';
import webBg3 from '../../assets/img/web-bg3-min.webp';
import webBgAa from '../../assets/img/web-bg-aa.webp';

import 'swiper/css';
import 'swiper/css/effect-fade';
import Newsletter from './Newsletter';

type SliderContainerProps = {
  bgImg: string;
};

const SliderContainer = styled('div')<SliderContainerProps>(({ bgImg }) => ({
  maxWidth: '100%',
  minHeight: '100vh',
  background: `url(${bgImg}) center center no-repeat`,
  backgroundSize: 'cover',
}));

const Hero = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'newsletter' : undefined;
  const handleLink = (link: string) => {
    navigate(link);
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        effect={'fade'}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, EffectFade]}
        className="mySwiper"
      >
        {intros.map((intro) => (
          <SwiperSlide key={intro.id}>
            <SliderContainer bgImg={intro.bg}>
              <Container maxWidth="lg" sx={styles.container}>
                <Box component="div" sx={styles.bodyFlex}>
                  <Box component="div" sx={[styles.heroText, styles.header]}>
                    <Typography variant="h2" sx={styles.flex}>
                      <Box component="span" sx={styles.line} />
                      {t(intro.title)}
                    </Typography>
                    <Typography variant="h6" sx={styles.lightText}>
                      {t(intro.lightText)}
                    </Typography>
                    <Typography variant="h6" sx={styles.boldText}>
                      {t(intro.boldText)}
                    </Typography>
                    <Box component="div" sx={styles.buttonCon}>
                      <Button
                        id={`${intro.id}-${intro.button1.link}`}
                        onClick={() => navigate(`${intro.button1.link}`)}
                        variant="outlined"
                        sx={styles.button1}
                      >
                        {t(intro.button1.text)}
                      </Button>
                      {intro.button2.link === '/newuser' ? (
                        <Button
                          onClick={handleClick}
                          variant="contained"
                          sx={styles.button2}
                        >
                          {t(intro.button2.text)}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleLink(`${intro.button2.link}`)}
                          variant="contained"
                          sx={styles.button2}
                        >
                          {t(intro.button2.text)}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </SliderContainer>
          </SwiperSlide>
        ))}
      </Swiper>

      <img
        src={backgroundTriangle}
        alt="background triangle"
        style={styles.img as React.CSSProperties | undefined}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Newsletter />
      </Popover>
    </Box>
  );
};

export default Hero;

const intros = [
  {
    id: 1,
    title: 'hero.intros.intro1.title',
    lightText: 'hero.intros.intro1.lightText',
    boldText: 'hero.intros.intro1.boldText',
    button1: { text: 'hero.intros.intro1.button1', link: '/contact' },
    button2: { text: 'hero.intros.intro1.button2', link: '/newuser' },
    bg: webBg1,
  },
  {
    id: 2,
    title: 'hero.intros.intro2.title',
    lightText: 'hero.intros.intro2.lightText',
    boldText: 'hero.intros.intro2.boldText',
    button1: { text: 'hero.intros.intro2.button1', link: 'services' },
    button2: { text: 'hero.intros.intro2.button2', link: 'appointment' },
    bg: webBg2,
  },
  {
    id: 3,
    title: 'hero.intros.intro3.title',
    lightText: 'hero.intros.intro3.lightText',
    boldText: 'hero.intros.intro3.boldText',
    button1: { text: 'hero.intros.intro3.button1', link: 'portfolio' },
    button2: { text: 'hero.intros.intro3.button2', link: 'services' },
    bg: webBg3,
  },
  {
    id: 4,
    title: 'hero.intros.intro4.title',
    lightText: 'hero.intros.intro4.lightText',
    boldText: 'hero.intros.intro4.boldText',
    button1: { text: 'hero.intros.intro4.button1', link: 'newuser' },
    button2: { text: 'hero.intros.intro4.button2', link: 'contact' },
    bg: webBgAa,
  },
];
