import { CloudUpload, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Buffer } from 'buffer';
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState, useContext } from 'react';
import S3 from 'react-aws-s3';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Editor as TinyMCEEditor } from 'tinymce';
import { addNewBlog } from '../../store/api';
import { AppEnum, tBlogInput } from '../../store/types';
import Portal from './Portal';
import { Appcontext } from '../../context/AppContext';
import useAlert from '../../hooks/useAlert';

interface BlogFormData {
  title: string;
  brief: string;
  category: string;
  image?: string;
}

interface ImageUploadProps {
  field: {
    onChange: (value: string) => void;
    onBlur: () => void;
    value: string | undefined;
    name: string;
    ref: React.Ref<HTMLInputElement>;
  };
  error?: {
    message?: string;
  };
  label: string;
  onImageUpload: (imageUrl: string) => void;
}

const EDITOR_CONFIG = {
  height: 500,
  menubar: true,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ],
  toolbar: `undo redo | formatselect | bold italic backcolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | help`,
  content_style:
    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
};

export const S3_CONFIG = {
  bucketName: process.env.REACT_APP_bucket,
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_aws_key,
  secretAccessKey: process.env.REACT_APP_aws_secret,
  s3Url: 'https://aefinancials.s3.amazonaws.com',
};

window.Buffer = window.Buffer || Buffer;

// const defaultPlugins =
//   "preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons";
// const defaultToolbar =
//   "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link | ltr rtl";
// const defaultQuickLinks =
//   "bold italic | quicklink h2 h3 blockquote quickimage quicktable";

const ImageUploader: React.FC<ImageUploadProps> = ({
  field,
  error,
  label,
  onImageUpload,
}) => {
  const { setAlert, alertDisplay } = useAlert();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  const s3Client = new S3(S3_CONFIG);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) return;

    if (!selectedFile.type.match(/^image\/(png|jpeg|jpg|gif)$/)) {
      setAlert({
        type: 'warning',
        message: 'Please select a valid image file',
        open: true,
      });
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) {
      setAlert({
        message: 'File size should be less than 5MB',
        type: 'error',
        open: true,
      });
      return;
    }

    try {
      const upload = await s3Client.uploadFile(selectedFile, selectedFile.name);
      setFile(selectedFile);
      onImageUpload(upload.location);
      setAlert({
        type: 'success',
        message: 'Image uploaded successfully',
        open: true,
      });
    } catch (error) {
      setAlert({
        type: 'error',
        message: 'Failed to upload image',
        open: true,
      });
      throw new Error(String(error));
    }
  };

  const handleDelete = async () => {
    if (file) {
      try {
        await s3Client.deleteFile(file.name);
        setFile(null);
        onImageUpload('');
        setAlert({
          type: 'success',
          message: 'Image deleted successfully',
          open: true,
        });
      } catch (error) {
        setAlert({
          type: 'error',
          message: 'Failed to delete image',
          open: true,
        });
        throw new Error(String(error));
      }
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      {alertDisplay()}

      {file ? (
        <Card>
          <CardMedia
            component="img"
            image={URL.createObjectURL(file)}
            alt="Blog image"
            sx={{ height: 200, objectFit: 'cover' }}
          />
          <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button startIcon={<Delete />} color="error" onClick={handleDelete}>
              Remove Image
            </Button>
          </Box>
        </Card>
      ) : (
        <TextField
          {...field}
          type="file"
          inputRef={fileInputRef}
          onChange={handleFileUpload}
          error={!!error}
          helperText={error?.message || ''}
          label={label}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => fileInputRef.current?.click()}>
                  <CloudUpload />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Box>
  );
};

const NewBlog = () => {
  const { setAlert, alertDisplay } = useAlert();
  const { user } = useContext(Appcontext);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [content, setContent] = useState<string>('');

  const { control, handleSubmit, reset } = useForm<BlogFormData>({
    defaultValues: {
      title: '',
      brief: '',
      category: '',
      image: '',
    },
  });

  const { mutate, isLoading } = useMutation(addNewBlog, {
    onSuccess: () => {
      setAlert({
        open: true,
        type: 'success',
        message: 'Blog published successfully!',
      });
      reset();
      setContent('');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onError: () => {
      setAlert({
        open: true,
        type: 'error',
        message: 'Failed to publish blog. Please try again.',
      });
    },
  });

  const handleBlogSubmit = (data: BlogFormData) => {
    if (!content) {
      setAlert({
        open: true,
        type: 'error',
        message: 'Please add some content to your blog',
      });
      return;
    }

    const blogData: tBlogInput = {
      ...data,
      details: content,
      app: AppEnum.aefinance,
      tags: [data.category],
      author: `${user?.firstname} ${user?.lastname}`,
    };

    mutate(blogData);
  };

  return (
    <Portal>
      <Container>
        <Paper sx={{ p: 3, my: 4 }} elevation={3}>
          <Typography variant="h4" gutterBottom>
            Create New Blog Post
          </Typography>

          {alertDisplay()}

          <Box component="form" noValidate>
            <Controller
              name="title"
              control={control}
              rules={{ required: 'Title is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Blog Title"
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                  margin="normal"
                />
              )}
            />

            <Controller
              name="brief"
              control={control}
              rules={{ required: 'Brief description is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Brief Description"
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={2}
                />
              )}
            />

            <Controller
              name="category"
              control={control}
              rules={{ required: 'Category is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Category"
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                  margin="normal"
                />
              )}
            />

            <Controller
              name="image"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ImageUploader
                  field={field}
                  error={error}
                  label="Blog Image"
                  onImageUpload={(url) => field.onChange(url)}
                />
              )}
            />

            <Box sx={{ my: 3 }}>
              <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                onInit={(_evt, editor) => (editorRef.current = editor)}
                initialValue="<p>Start writing your blog post...</p>"
                init={EDITOR_CONFIG}
                onEditorChange={(content) => setContent(content)}
              />
            </Box>

            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleSubmit(handleBlogSubmit)}
              disabled={isLoading}
            >
              {isLoading ? 'Publishing...' : 'Publish Blog'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Portal>
  );
};

export default NewBlog;
