import { addTicket } from '../../store/api';
import { useContext, useState } from 'react';
import { Appcontext, AppDpx } from '../../context/AppContext';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  LinearProgress,
  Alert,
} from '@mui/material';
import backgroundTriangle from '../../assets/img/bgn-triangle-top.webp';
import { useForm, Controller } from 'react-hook-form';
import { LOADING_SET } from '../../context/Action';
import { useTranslation } from 'react-i18next';
import { formStyles } from '../../assets/styles/home';
import { tContact } from '../../store/types';

const Form = () => {
  const { t } = useTranslation();
  const [al, showAlert] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm<tContact>();
  const dispatch = useContext(AppDpx);
  const { loading } = useContext(Appcontext);
  const _submit = async (data: tContact) => {
    dispatch({ type: LOADING_SET, data: true });
    const d = await addTicket(data);
    if (d) {
      showAlert(true);
    }
    dispatch({ type: LOADING_SET, data: false });
    reset({ firstname: '', lastname: '', email: '', phone: '', message: '' });
  };
  return (
    <Box sx={formStyles.relative}>
      <Box component="div">
        <Box sx={formStyles.bodyFlex}>
          <Box component="div" sx={formStyles.bgImg}></Box>
          <Box component="div" sx={formStyles.formContainer}>
            <Box sx={{ maxWidth: '600px' }}>
              <Box sx={formStyles.header}>
                <Typography variant="h6" sx={formStyles.flex}>
                  <Box component="span" sx={formStyles.line} />
                  {t('formSection.title')}
                </Typography>

                <Typography variant="h3" sx={formStyles.lightText}>
                  {t('formSection.intro')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('formSection.description')}
                </Typography>
              </Box>
              {loading && <LinearProgress />}
              {al && (
                <Alert
                  sx={{ textAlign: 'center' }}
                  severity="success"
                  onClose={() => showAlert(false)}
                >
                  {t('formSection.alert')}
                </Alert>
              )}
              <Box component="form" sx={formStyles.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="firstname"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          id="firstname"
                          label={t('contactForm.firstname')}
                          variant="outlined"
                          fullWidth
                          sx={formStyles.formField}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="lastname"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          id="lastname"
                          label={t('contactForm.lastname')}
                          variant="outlined"
                          fullWidth
                          sx={formStyles.formField}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: true,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                          message: t('forms.newuser.erroremail'),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          type="email"
                          id="email"
                          label={t('contactForm.email')}
                          variant="outlined"
                          fullWidth
                          sx={formStyles.formField}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        pattern: {
                          value: /^\s*-?[0-9]{1,10}\s*$/,
                          message: t('forms.newuser.phoneauth'),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          id="phone"
                          label={t('contactForm.phone')}
                          variant="outlined"
                          fullWidth
                          sx={formStyles.formField}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Controller
                  name="message"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      id="comment"
                      multiline
                      rows={4}
                      label={t('contactForm.message')}
                      variant="outlined"
                      sx={formStyles.formField}
                      fullWidth
                    />
                  )}
                />

                <Box component="div" sx={{ marginTop: 3 }}>
                  <Button
                    id="home-contact"
                    variant="contained"
                    sx={formStyles.button}
                    onClick={handleSubmit(_submit)}
                  >
                    {t('formSection.button')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <img
        src={backgroundTriangle}
        alt="background triangle"
        style={formStyles.img as React.CSSProperties | undefined}
      />
    </Box>
  );
};

export default Form;
